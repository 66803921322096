<template>
  <div class="rmb">
    <topMenu></topMenu>
    <div class="logo_bg">
      <el-image :src="RMB">
        <div slot="placeholder">图片加载中</div>
      </el-image>
      <div class="main_title">Domestic Fund Administration Services</div>
    </div>
    <!-- 大类 -->
    <div class="broad_category flex justify_content">
      <div class="item" v-for="(item, index) in rmbList" :key="index">
        <img :src="item.img" alt="">
        <p>{{item.name}}</p>
      </div>
    </div>
    <Contact :dialog="contactStatus" :language="language"></Contact>
    <div class="introduce_bg">
      <!-- 管理人服务 -->
      <div class="manage flex">
        <div class="left">
          <img :src="rmb_1" alt="" style="width: 210px; height: 210px;">
        </div>
        <div class="right">
          <div class="title">Private Fund Manager Services</div>
          <div class="content">
            <ul class="flex" style="flex-wrap: wrap;">
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>Fund formation consulting</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>Audit liaison</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>Bookkeeping and maintain accounting records</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>AMAC filing assistance</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>Filing material changes of fund managers</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>Compliance/Tax filing assistance</span></li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 基金全生命周期服务* -->
      <div class="foundation flex justify_content">
        <div class="left">
          <div class="title" style="color: #fff;">Fund Full Lifecycle Services*</div>
          <div class="content">
            <ul class="flex" style="flex-wrap: wrap;">
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>Filing consulting</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>Transfer agency (including investor capital activities</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>Investment entities coordination</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><span style="padding-left: 14px;">and capital account statements)</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>Bank account opening assistance (e.g. custodian bank)</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>Filing information to requested authorities</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>Audit liaison</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>Compliance and tax filing assistance</span></li>
              <li class="rmb_item flex align_items rmb_content"><div class="circle"></div><span>Bookkeeping and maintain accounting records</span></li>
              <li class="rmb_item1 flex align_items rmb_content"><div class="circle"></div><span>Fund liquidation assistance</span></li>
            </ul>
          </div>
        </div>
        <div class="right">
          <img :src="rmb_4" alt="" style="width: 200px; height: 200px;">
          <p style="padding-top: 25px;">*Part of the services are provided in</p>
          <p>collaboration with other professional institutions</p>
        </div>
      </div>
      <!-- 元利签电子签约服务 -->
      <div class="manage flex">
        <div class="left">
          <img :src="rmb_3" alt="" style="width: 210px; height: 210px;">
        </div>
        <div class="right">
          <div class="title">G-Sign Electronic Signature Services</div>
          <div class="content">
            <ul style="padding-left: 25px;">
              <li>Adequacy system</li>
              <li>Online contract administration</li>
              <li>Signing of econtracting</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="tip">
      <p>Genesis also welcomes inquiries about QFLP and QDLP applications and compliance consulting of private equity fund. </p>
    </div>
    <div class="footer" id="footer">
      <FooterBottom :language="language"></FooterBottom>
    </div>
  </div>
</template>

<script>
import TopMenu from '../menuEnglish.vue';
import Contact from '../contact';
import FooterBottom from '../footer';
import RMB from '../../assets/rmb_bg.png';
import rmb_1 from '../../assets/rmb_1.png';
import rmb_2 from '../../assets/rmb_2.png';
import rmb_3 from '../../assets/rmb_3.png';
import rmb_4 from '../../assets/rmb_4.png';

export default {
  name: 'RMB',
  components: {TopMenu, Contact, FooterBottom},
  data() {
    return {
      RMB,
      rmb_1,
      rmb_2,
      rmb_3,
      rmb_4,
      rmbList: [
        { img: rmb_1, name: 'Private Fund Manager Services' },
        { img: rmb_2, name: 'Fund Full Lifecycle Services*' },
        { img: rmb_3, name: 'G-Sign Electronic Signature Services' },
      ],
      contactStatus: false,
      language: 'english',
    }
  }
}  
</script>

<style scoped lang="scss">
  .rmb {
    /*大背景*/
    .logo_bg{
      position: relative;
      padding-top: 90px;
      .main_title {
        position: absolute;
        left: 110px;
        bottom: 25%;
        font-size: 60px;
        font-weight: bold;
        color: #fff;
      }
    }
    .logo_bg .el-image{
      height: 100%;
      width: 100%;
    }
    /*大类 */
    .broad_category {
      padding: 60px 210px 80px 210px;
      .item {
        width: 31.5%;
        border: 1px solid #E0E0E0;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        text-align: center;
        img {
          width: 170px;
          height: 170px;
          margin: 30px 0;
        }
        p {
          color: #21374E;
          font-size: 29px;
          font-weight: bold;
          padding: 0 20px 50px;
        }
      }
    }
    .title {
      font-size: 40px;
      font-weight: bold;
      color: #21374E;
      padding-bottom: 30px;
    }
    ul>li {
      font-size: 20px;
      line-height: 40px;
    }
    .introduce_bg {
      background: url("../../assets/introduce_bg.png");
    }
    .rmb_item {
      width: 40%;
    }
    .rmb_item1 {
      width: 60%;
      padding-left: 25px;
    }
    .rmb_content {
      line-height: 40px;
      font-size: 20px;
    }
    .rmb_content span {
      width: calc(100% - 14px);
    }
    .circle {
      width: 4px;
      height: 4px;
      background: #000;
      border-radius: 50%;
      margin-right: 10px;
    }
    /*管理人服务 */
    .manage {
      padding: 60px 210px;
      .left {
        width: 30%;
      }
      .right {
        width: 70%;
      }
    }
    /*基金全生命周期服务 */
    .foundation {
      padding: 30px 210px;
      background: url("../../assets/rmb_5.jpg");
      color: #fff;

      .left {
        width: 80%;
        .rmb_item {
          width: 52%;
        }
        .rmb_item1 {
          width: 48%;
          padding-left: 15px;
        }
        .circle {
          width: 4px;
          height: 4px;
          background: #fff;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      .right {
        width: 24%;
        text-align: right;
        img {
          margin-top: 30px;
        }
        p {
          font-size: 14px;
          padding-right: 20px;
          line-height: 25px;
        }
      }
    }
    .tip {
      background: url("../../assets/footer_1.png");
      color: #fff;
      text-align: center;
      font-size: 20px;
      padding: 80px 0 40px;
      line-height: 35px;
    }
  }
  
</style>