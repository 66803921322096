export const positionList = [
    {
      name: '基金会计',
      time: '2024-1-2',
      department: '',
      location: '上海/北京',
      detail: {
        goal: '在相关项目负责人的统筹安排下，由高级员工指导完成基金的会计核算、估值、报表编制等工作。',
        egGoal: '',
        duty: [
          {
            id: 1, title: '', 
            content: ['1.准确完成会计核算、记账、报表出具、纳税申报以及会计档案管理等工作；',
              '2.及时准确地处理投资者的申购、赎回、份额转让请求；',
              '3.准确完成提款通知、分配通知的计算，协助发送通知书；',
              '4.定期完成基金报告及投资者账户的编制；',
              '5.协助完成相关投资人系统的数据填报及监管要求下的数据申报；',
              '6.协助年度审计；',
              '7.根据客户、部门及公司需要积极完成本职内的其他工作。',]
          }    
        ],
        require: [
          {
            id: 1, title: '', 
            content: ['1.全日制本科以上学历，经济、金融、会计、财务等相关专业；',
            '2.取得或部分取得会计类相关证书者优先（如ACCA/CPAA/CICPA/AICPA/CIMA）；',
            '3.有相关基金系统或会计系统使用经验优先（如金蝶，用友，Efront，Paxus等）；',
            '4.有3年以上基金相关投资主体的会计核算经验；',
            '5.良好的英文运用能力，熟悉办公自动化软件；',
            '6.认真、细致、有强烈的工作责任心；',
            '7.有客户服务意识，善于沟通，有团队精神，有较强的抗压能力；',
            '8.勤于思考，有随时应对新事物的积极态度，有快速学习和运用的能力。',]
          }
        ],
      },
      id: 1
    },
    {
      name: '交易支持(项目驻场)',
      time: '2024-1-2',
      department: '人资服务事业部',
      location: '上海',
      detail: {
        goal: '驻场在持牌机构总部，协助客户处理私募产品尽调入库，私募产品申购赎回，部门内月报编制等工作。',
        duty: [{
          id: 1, title: '', 
          content: ['1.协助权益投资部处理私募基金产品尽调入库流程：负责与确认入库的私募管理人沟通，进行尽调入库材料的审核与需入库私募产品历史业绩、评分表的制作。',
            '2.协助权益投资部进行月报的编制：负责制作存续FOF产品的周报以及月报数据，计算FOF产品每月收益率、夏普比率，提供月报数据支持。',
            '3.协助交易部进行私募产品的申购赎回等交易流程：负责与私募管理人沟通基金产品申购、赎回的适当性材料，处理投资者适当性材料的制作、私募基金产品合同用印盖章以及交易结束后的归档流程。',
            '4.负责其他交易或投研相关的基础性支持工作。',]
          }
        ],
        require: [{
          id: 1, title: '',
          content: ['1.女生优先，专业经济金融、会计等相关专业优先。',
            '2.个性沉稳，做事细心。愿意从事一些基础性工作。',
            '3.对金融有一定的了解和认知，热爱金融行业，工作积极性较高。',]
          }
        ],
      },
      id: 2
    },
    {
      name: '清算助理(项目驻场)',
      time: '2024-1-2',
      department: '人资服务事业部',
      location: '上海',
      detail: {
        goal: '驻场在持牌机构总部，协助客户从事基金清算结算等工作。',
        egGoal: '',
        duty: [{
          id: 1, title: '',
          content: ['1.负责各项指令交易、非交易指令的接收和处理，审核投资附件的完整性及有效性，指令要素审核等，完成日常资金清算衔接运作流程，确保指令分拣和录入及时准确；',
            '2.根据指令附件维护账户信息；',
            '3.负责本岗位涉及的内外部机构联络、指令归档整理、相关系统参数设置、配合系统测试及报表编制等；',
            '4.根据客户和上级要求完成本职内的其他相关工作。']
          }
        ],
        require: [{
          id: 1, title: '',
          content: ['1.全日制本科以上学历，财务类、经管类相关专业；',
            '2.应届毕业生；有1年左右财务、审计等相关工作经验优先；',
            '3.良好的中文、英文运用能力，熟悉办公自动化软件；',
            '4.认真、细致、有强烈的工作责任心；',
            '5.有客户服务意识，善于沟通；',
            '6.较强的团队意识和抗压能力；',
            '7.较强的风险意识，勤于思考；',
            '8.能够服从公司安排，接受并完成派驻工作；',
            '9.到岗6个月内必须通过基金从业资格考试。']
          }
        ],
      },
      id: 3
    },
    {
      name: '基金会计(项目驻场)',
      time: '2024-1-2',
      department: '人资服务事业部',
      location: '上海',
      detail: {
        goal: '驻场在持牌机构总部，协助客户从事基金的净值核算等工作。',
        egGoal: '',
        duty: [{
          id: 1, title: '',
          content: ['1.维护估值系统的基金参数；负责处理基金的会计核算，按时保质完成每日估值和对账工作；',
            '2.协助编制月度/季度/半年度/年度财务报表和纳税申报表； ',
            '3.参与撰写业务测试报告，按计划实施业务测试；',
            '4.保持与派驻机构的友好关系，及时耐心处理客户疑问；',
            '5.根据客户和上级要求完成本职内的其他相关工作。',]
          }
        ],
        require: [{
          id: 1, title: '',
          content: ['1.全日制本科以上学历，经济、金融、会计、审计等相关专业优先考虑；',
            '2.有1年左右相关工作经验优先；',
            '3.良好的中文、英文运用能力，熟悉办公自动化软件；',
            '4.认真、细致、有强烈的工作责任心；',
            '5.有客户服务意识，善于沟通；较强的团队意识和抗压能力；',
            '6.较强的风险意识，勤于思考；',
            '7.能够服从公司安排，接受并完成派驻工作；',
            '8.到岗6个月内必须通过基金从业资格考试。']
          }
        ],
      },
      id: 4
    },
    {
      name: '金融产品助理(项目驻场)',
      time: '2024-1-2',
      department: '人资服务事业部',
      location: '上海',
      detail: {
        goal: '驻场在持牌机构总部，协助客户制作/审核基金产品合同，处理基金基础运营流程等工作。',
        egGoal: '',
        duty: [{
          id: 1, title: '',
          content: ['1.承接产品管理组内分配的各类合同/协议制作；',
            '2.部分组内合同/协议的部分审核；',
            '3.协助处理组内相关基础运营流程，包括但不限于补充协议复核流程、合同加印流程、管理人信息变更流程等；',
            '4.根据组内要求，作为需求提交人提交组内系统需求，并负责系统测试，参与组内安排的其他系统测试并准时提交测试报告；',
            '5.组内日常工作中文档的汇总及整理，包括但不限于各类需求搜集统计、各类数据清理、组内会议材料整理等；',
            '6.按照要求时效，协助进行协议回收；',
            '7.根据组内安排，参与客户拜访及后续需求设计、推动；',
            '8.对接管理人服务平台电话咨询工作；',
            '9.团队负责人安排且经职能组负责人批准的其他相关工作。',]
          }
        ],
        require: [{
          id: 1, title: '',
          content: ['1.本科学历及以上；',
            '2.具有证券类私募基金公司实习经验为佳；',
            '3.文字功底扎实，有大量文本处理经验，熟练操作word、excel等OFFICE办公软件；',
            '4.做事踏实仔细、责任心强、愿意接受及学习新事物；',
            '5.抗压能力强，能接受适当加班。',]
          }
        ],
      },
      id: 5
    },
    {
      name: '营销支持岗(项目驻场)',
      time: '2024-1-2',
      department: '人资服务事业部',
      location: '上海',
      detail: {
        goal: '',
        egGoal: '',
        duty: [{
          id: 1, title: '',
          content: ['该职位的总体职责是协助开发新产品，管理整个产品生命周期，创建信息并支持分销（营销和销售支持）工作。',]}
        ],
        require: [{
          id: 1, title: '',
          content: ['1.协助和协调整体新产品开发流程，确保客户产品的有效推出和持续管理；',
            '2.在其职权范围内，项目管理和及时实施新基金发行和注册、基金变更和基金清算；',
            '3.协助并协调其范围内项目的产品审批流程，适当尊重客户的程序和流程；',
            '4.开发产品材料；',
            '5.帮助解决客户对产品的要求；',
            '6.协助提供优质的产品相关客户服务；',
            '7.协助准备客户会议、客户报告和投资组合审查等产品资料；',
            '8.管理新帐户设立和转换流程；',
            '9.为定期和临时的产品发布活动和为活动提供支持，包括社交媒体和网站产品资料更新；',
            '10.要求英语书写流利和口语日常交流能力。',]
          }
        ],
      },
      id: 6
  },
  {
    name: '衍生品确权岗(项目驻场)',
    time: '2024-1-2',
    department: '人资服务事业部',
    location: '上海',
    detail: {
      goal: '',
      egGoal: '',
      duty: [{
          id: 1, title: '',
          content: ['1.场外投资业务投资标的基本信息的采集；',
          '2.场外投资业务的划款指令信息的采集；',
          '3.场外投资业务的对外投资确权信息的采集；',
          '4.场外投资业务的回款业务信息的采集；',
          '5.场外投资业务的净值及权益变动信息的采集；',
          '6.券商对账单中非标准化数据信息的采集；',
          '7.券商佣金费率数据的采集；',
          '8.券商户利率数据的采集；',
          '9.场外衍生品交易数据及行情数据的采集；',
          '10.场外资产开放日持仓、行情数据的采集；',
          '11.其他相关工作。',]
        }
      ],
      require: [{
        id: 1, title: '',
        content: ['1.具备基础基金会计专业能力；',
          '2.熟练掌握各项公募基金、私募基金、资管计划等资管类标的投资业务的估值账务处理能力；',
          '3.熟练掌握场外衍生品投资业务的估值账务处理能力；',
          '4.可熟练使用恒生估值系统、协作平台-估值数据管理模块、数据园地等数据运营相关操作系统；',
          '5.具备较强的逻辑推理、归因分析、流程优化思考及自主学习能力。',]
        }
      ],
    },
    id: 7
  },
  {
    name: '基金运营助理(项目驻场)',
    time: '2024-1-2',
    department: '人资服务事业部',
    location: '上海/福州',
    detail: {
      goal: '',
      egGoal: '',
      duty: [{
        id: 1, title: '',
        content: ['1. 协助处理基金的会计核算，按时保质完成每日估值和对账工作；',
          '2. 协助编制月度/季度/半年度/年度财务报表和纳税申报表；',
          '3. 负责本岗位涉及的内外部机构联络、指令归档整理、相关系统参数设置、配合系统测试等；',
          '4. 根据客户和上级要求完成本职内的其他相关工作。',]
        }
      ],
      require: [{
        id: 1, title: '1. 教育及经验要求',
        content: ['1）教育背景：一本以上学校，金融、会计、法律相关专业；',
          '2）能力要求：具备英文能力，工作材料、会议等涉及英文；',
          '3）工作背景：有证券基金业运营或合规经验优先考虑；',
          '4）其它要求：到岗6个月内必须通过基金从业资格考试。',]
        },
        {
          id: 2, title: '2. 资格能力要求',
          content: ['1）优秀的学习能力及自驱力；',
            '2）认真、细致、有强烈的工作责任心；',
            '3）有客户服务意识，善于沟通；',
            '4）较强的团队意识和抗压能力；',
            '5）较强的风险意识，勤于思考；',
            '6）能够服从公司安排，接受并完成派驻工作。',]
        }
      ],
    },
    id: 8
  },
  {
    name: '数据管理岗-数据验证与研究方向(项目驻场)',
    time: '2024-1-2',
    department: '人资服务事业部',
    location: '上海',
    detail: {
      goal: '驻场在持牌机构总部，协助客户从事数据测试、敏捷服务、数据分析、数据治理等工作。',
      duty: [
        {
          id: 1, title: '', 
          content: ['1.数据测试。针对数据实验室的面向业务的基础数据集，开展数据质量业务验证，反馈并跟进解决数据问题，持续监控数据质量。',
            '2.敏捷服务。调研和整合业务需求，基于数据实验室的基础数据集与BI、Python 等分析工具，敏捷研制数据功能，并根据业务反馈快速迭代；参与探索和完善敏捷数据服务机制，并培训推广实验室的数据、工具及功能。',
            '3.数据分析。自主分析业务数据，发掘数据背后的业务规律，发挥数据资产价值。',
            '4.数据治理。协助数据标准整理维护、数据质量规则梳理、数据资产目录编制等数据治理工作。',]
        }
      ],
      require: [
        {
          id: 1, title: '', 
          content: ['1.本科及以上学历，计算机、信息管理、统计、金融工程、数理金融等相关专业，30周岁（含）以下。',
          '2.具有2年及以上工作经验，拥有数据测试、BI报表开发、Python 分析数据的经验；具备头部券商、银行、资管机构或互联网大厂数据分析和数据治理经历的优先。',
          '3.熟练掌握 SOL、Python 与Microsoft Office办公软件，具备SmartBI、帆软、Tableau 等一种或多种 BI工具的开发能力；拥有VBA、R等数据分析能力者优先。',
          '4.具有较强的工作责任心与求知欲，良好的沟通和协调能力，严谨、细致、踏实的工作作风和积极主动的工作态度，能承受较大的工作压力。',
          '5.身体健康，品行端正，无任何违规违纪行为。',]
        }  
      ],
    },
    id: 9
  },
  {
    name: '桌面运维(项目驻场)',
    time: '2024-1-2',
    department: '人资服务事业部',
    location: '上海',
    detail: {
      goal: '',
      egGoal: '',
      duty: [
        { id: 1, title: '桌面运维占比30%，机房维护管理占30%，其他行政采购类占40%。', 
          content: [
            '1.系统管理：维护业务系统（电话、文件、财务等），确保业务系统正常运行；',
            '2.软件管理：软件的安装维护和使用,对软件进行统一管理；',
            '3.硬件管理：服务器故障维护 (文件备份，恢复，系统漏洞检测，病毒检测，清除等)；客户端故障维护（包括系统安装，系统更新，常用软件安装）；电脑常见外设故障维护（打印机，扫描仪等）；',
            '4.网络管理：优化企业网络，局域网故障维修(对现有公司内部局域网络维护、测试，保证内部网络通讯正常，网络故障的解决等)；',
            '5.IT设备管理：清点硬件，做好硬件变更管理，用户权限设置（包括用户权限，密码等设置）；客户文档的制作（包括网络设置，硬件列表，用户列表等文档）；',
            '6.为IT设备和服务的采购提供符合实情的建议。',
          ]
        }
      ],
      require: [
        { id: 1, title: '', 
          content: [
            '1.计算机、通信类相关专业优先，大专及以上学历；',
            '2.具有1年以上金融行业桌面运维经验；',
            '3.熟悉TCP/IP等网络协议，熟悉Linux系统的体系结构，掌握WINDOWS Server/XP/win7/win10操作系统, 熟悉操作系统的配置和管理；掌握Windows AD域环境常见配置和管理；',
            '4.踏实、稳重，有良好的组织沟通、协调能力，具有团队协作精神和客户服务意识。',
          ]
        }
      ],
    },
    id: 10
  },
  {
    name: '信息披露(项目驻场)',
    time: '2024-1-2',
    department: '人资服务事业部',
    location: '上海',
    detail: {
      goal: '驻场在持牌机构总部，协助客户审核、制作、上挂、发送理财产品信披报告等工作。',
      duty: [{ id: 1, title: '', 
      content: ['1.审核和制作信息披露报告。协助进行理财产品信息披露工作，接收和审核常规信息披露报告，制作各类定期、临时信息披露报告。',
        '2.上挂和发送信息披露报告。将各类信息披露报告上挂至公司官方披露渠道、销售机构指定系统/渠道。',
        '3.流程优化。协助撰写信息披露操作手册，对信息披露流程提出优化建议，对信息披露工作进行智能化效率提升。',
        '4.系统验证。协助对公司资管运营平台信息披露模块和信息披露相关系统建设提出优化建议，对系统功能进行测试验收。',]
        }
      ],
      require: [{ id: 1, title: '', 
      content: ['1.本科以上学历，财会、税务、计算机等相关专业优先考虑，性别、户籍不限，身体健康，35周岁及以下；',
          '2.具有资管运营业务相关工作经验，了解系统需求提交或资管系统建设方法，有一定的数据处理和程序开发能力，能使用vb、python或sql等工具；',
          '3.熟练运用excel等office办公软件；',
          '4.工作细致，责任心强，有良好的服务理念；',
          '5.具有较强的沟通协调能力、执行力和文字表达能力。',]
        }
      ],
    },
    id: 11
  },
  {
    name: '数据管理岗-数据统计方向(项目驻场)',
    time: '2024-1-2',
    department: '人资服务事业部',
    location: '上海',
    detail: {
      goal: '驻场在持牌机构总部，协助客户从事理财产品登记，数据采集与清洗等工作。',
      duty: [{ id: 1, title: '', 
        content: ['1.理财登记服务。协助做好在理财登记系统的登记操作工作，协助做好相关系统功能优化。',
          '2.数据采集与清洗服务。协助收集第三方管理人的资管产品底层持仓和要素信息，收集和完善数据统计管理中所需的各项基础信息，协助做好相关系统功能优化。',
          '3.数据统计服务。协助做好理财业务数据的内外部统计工作。',]
        }
      ],
      require: [{ id: 1, title: '', 
        content: ['1.本科及以上学历，计算机、信息管理、统计、金融工程、数理金融等相关专业，30周岁（含）以下；',
          '2.具有1年及以上工作经验，有数据统计与数据报送的工作经验；具备头部券商、银行、资管机构或互联网大厂数据管理工作经历的优先；',
          '3.熟练运用Microsoft Office办公软件；具备SQL、VBA、Python、R等数据分析能力或SmartBI、帆软、Tableau等BI工具开发能力的优先；',
          '4.具有较强的工作责任心，良好的沟通和协调能力，严谨、细致、踏实的工作作风和积极主动的工作态度，能承受较大的工作压力；',
          '5.身体健康，品行端正，无任何违规违纪行为。',]
        }
      ],
    },
    id: 12
  },
  {
    name: '数据验证与研究方向服务助理(项目驻场)',
    time: '2024-1-2',
    department: '人资服务事业部',
    location: '上海',
    detail: {
      goal: '',
      duty: [{ id: 1, title: '', 
        content: ['1.数据测试。针对数据实验室的面向业务的基础数据集，开展数据质量业务验证，反馈并跟进解决数据问题，持续监控数据质量。',
          '2.敏捷服务。调研和整合业务需求，基于数据实验室的基础数据集与BI、Python 等分析工具，敏捷研制数据功能，并根据业务反馈快速迭代；参与探索和完善敏捷数据服务机制，并培训推广实验室的数据、工具及功能。',
          '3.数据分析。自主分析业务数据，发掘数据背后的业务规律，发挥数据资产价值。',
          '4. 数据治理。协助数据标准整理维护、数据质量规则梳理、数据资产目录编制等数据治理工作。',]
        }
      ],
      require: [{ id: 1, title: '', 
        content: ['1.一本以上学校，计算机、信息管理、统计、金融工程、数理金融等相关专业，30周岁（含）以下；',
          '2.具有2年及以上工作经验，拥有数据测试、BI报表开发、Python 分析数据的经验；具备头部券商、银行、资管机构或互联网大厂数据分析和数据治理经历的优先；',
          '3.熟练掌握 SQL、Python 与Microsoft Office办公软件，具备SmartBI、帆软、Tableau 等一种或多种 BI工具的开发能力；拥有VBA、R语言数据分析能力者优先。非IT开发人员；',
          '4.具有较强的工作责任心与求知欲，良好的沟通和协调能力，严谨、细致、踏实的工作作风和积极主动的工作态度，能承受较大的工作压力。',]
        }
      ],
    },
    id: 13
  },
  {
    name: '数据统计方向服务助理(项目驻场)',
    time: '2024-1-2',
    department: '人资服务事业部',
    location: '上海',
    detail: {
      goal: '',
      duty: [{ id: 1, title: '', 
        content: ['1.理财登记服务。协助做好在理财登记系统的登记操作工作，协助做好相关系统功能优化。',
          '2.数据采集与清洗服务。协助收集第三方管理人的资管产品底层持仓和要素信息，收集和完善数据统计管理中所需的各项基础信息，协助做好相关系统功能优化。',
          '3.数据统计服务。协助做好理财业务数据的内外部统计工作。',]
        }
      ],
      require: [{ id: 1, title: '', 
        content: ['1.一本以上学校，计算机、信息管理、统计、金融工程、数理金融等相关专业，30周岁（含）以下；',
          '2.具有1年及以上工作经验，有数据统计与数据报送的工作经验；具备头部券商、银行、资管机构或互联网大厂数据管理工作经历的优先；',
          '3.熟练运用Microsoft Office办公软件；具备SQL、VBA、Python、R语言数据分析能力或SmartBI、帆软、Tableau等BI工具开发能力的优先；非IT开发人员；',
          '4.具有较强的工作责任心，良好的沟通和协调能力，严谨、细致、踏实的工作作风和积极主动的工作态度，能承受较大的工作压力。',]
        }
      ],
    },
    id: 14
  },
];
// 协会通知
export const associationNewsList = [
    {
        name: '中基协：5亿以上管理人需聘请“证券备案会所”出具审计',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652596623&idx=1&sn=e27cccdd61f0c579590b56b82b71832a&chksm=8bbd00e3bcca89f59431d725ebc9993cd1a15429528012502487da4b2b72e0eb76f236444f4d&token=360890238&lang=zh_CN#rd',
    },
    {
        name: '中国证券投资基金业协会关于发布2024年度考试计划的公告',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652595845&idx=1&sn=d7630819af3a169388395b359da86a5f&chksm=8bbd0fe9bcca86ff516f86662b74cbf6ac852e75a2ed822346caacb8bdf3e92d5a8fed168a8a&token=360890238&lang=zh_CN#rd',
    },
    {
        name: '协会通知｜关于发布修订后的《基金从业人员管理规则》及配套规则的公告',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652585989&idx=2&sn=3c09c6d8cce988f508f45adc2bb878ce&chksm=8bbd2969bccaa07fd292db4d8a4bd15860b79ec92536a26bf7c4a19dec56801904c808728808&token=360689050&lang=zh_CN#rd',
    },
    {
        name: '协会通知｜基金业协会修订基金从业人员有关自律规则 强化从业人员管理和自律约束',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652585989&idx=3&sn=e02cd36f7db76476b505958dd8a33929&chksm=8bbd2969bccaa07f8c73b6f812124d6dde72ee4786d83eb89a5c566fc4a70d3630c0da3d3c89&token=360689050&lang=zh_CN#rd',
    },
    {
        name: '协会通知｜《基金从业人员管理规则》及配套规则修订有关问题解答',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652585989&idx=4&sn=8b61234ebc4791c0f25785a3b0e0a7c2&chksm=8bbd2969bccaa07f638908659544f172af1a4c718e0703ae1606242171468f96499a6d767cfe&token=360689050&lang=zh_CN#rd',
    },
    {
        name: '协会通知｜关于举办《私募投资基金监督管理条例》及相关规则解读系列培训（第二期）的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652581800&idx=2&sn=b4d69fa726e6d5f594f75430f535c677&chksm=8bbd46c4bccacfd2058c307087e92f25017bb3deba17b8a766c131849f0181d8ba67117f1884&token=708883074&lang=zh_CN#rd',
    },
    {
        name: '协会公告｜中国证券投资基金业协会专题论文征稿启事',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652580654&idx=4&sn=4f06e2579af30b7873bc4f24dc88232c&chksm=8bbd4242bccacb545d3ff42a5bcf4754e23800620714f45162cdfaa3cb72d4ab53becc93160a&token=708883074&lang=zh_CN#rd',
    },
    {
        name: '重要！中国证券投资基金业协会发布《私募投资基金备案指引》及配套材料清单',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652580384&idx=1&sn=1dc775e2938f967723f7e7fefea3364d&chksm=8bbd434cbccaca5a5233eb4c5fac29a96a45bf67daed7dc4458087e5448ba419384e7776f38c&token=166763084&lang=zh_CN#rd',
    },
    {
        name: '关于发布《私募投资基金备案指引第1号——私募证券投资基金》《私募投资基金备案指引第2号——私募股权、创业投资基金》的公告',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652580384&idx=2&sn=6afefcf7a4425f2fdb72f73a89985a51&chksm=8bbd434cbccaca5af71f3ae23de1a4628c3faaadcea74c0723caca7e8d9c2ac4ae2e927de91a&token=166763084&lang=zh_CN#rd',
    },
    {
        name: '关于发布《私募投资基金备案指引第3号——私募投资基金变更管理人》的公告',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652580384&idx=3&sn=9ba88467ee38178b2a2698a9d3001f71&chksm=8bbd434cbccaca5a573ca073b7aeba30ec82f04878d99779bf8a8cb8f7f4b593dfdf8d5ab784&token=166763084&lang=zh_CN#rd',
    },
    {
        name: '协会通知｜严正声明',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652574420&idx=1&sn=04fb4747c39ca38a09a823b0b4625e48&chksm=8bbd7bb8bccaf2aebd4c021d73aa50066ef26423fe1d2fe0770ece92e4fb979d6a5b59023dcb&token=918575319&lang=zh_CN#rd',
    },
    {
        name: '最新！中基协发布首期《私募基金登记备案动态》',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652574066&idx=1&sn=b0a1502c9cbaf2e9b583430f3c845b56&chksm=8bbd781ebccaf108870664fae5772c0dad79455c0323f16be10120d2d4317951279b8ca00545&token=918575319&lang=zh_CN#rd',
    },
    {
        name: '协会通知｜关于填写2023年度基金管理人《绿色投资自评表》的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652572329&idx=3&sn=4330bb67e51a0931dd39277683cf583a&chksm=8bbd63c5bccaead36a64aad18b26c7a70f9e0367bfb8c4729f647d1e7ef9f164047b6c9cccb6&token=1753598493&lang=zh_CN#rd',
    },
    {
        name: '协会公告｜关于发布《证券期货经营机构私募资产管理计划备案办法》的公告',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652571306&idx=2&sn=ec0af19b9b5fb05c3606e482db82f0a9&chksm=8bbd6fc6bccae6d020decb804a84f10f3ca96366be73d4099778dd399c7b2ca0ece4fd9930c8&token=1753598493&lang=zh_CN#rd',
    },
    {
        name: '协会公告｜关于发布《中国证券投资基金业协会自律检查规则》的公告',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652571306&idx=3&sn=57103125d1e9400e0680b74766ee5701&chksm=8bbd6fc6bccae6d08cf66be138be104e154d70f94abccc3dbcf11e04be731ee8551dbd5da69a&token=1753598493&lang=zh_CN#rd',
    },
    {
        name: '协会公告｜关于发布《私募基金管理人失联处理指引》的公告',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652571306&idx=4&sn=ff5e7e809a898da4790eda400eea1993&chksm=8bbd6fc6bccae6d0779085057d8c96485e8f77cac6d3dbe34915382d02bb34bd7320b5d5e323&token=1753598493&lang=zh_CN#rd',
    },
    {
        name: '协会通知｜关于私募投资基金电子合同服务机构登记系统上线的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652565034&idx=3&sn=1d570900854e9ee9fe46928e91a6a09e&chksm=8bbc8746bccb0e50d4e1ffd93b6a90e0547ca2fb73a6daa1ec2ad7b2155cbd9433cd176c89f7&token=983337416&lang=zh_CN#rd',
    },
    {
        name: '协会通知｜关于发布2023年第一季度私募基金管理人会员信用信息报告的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652564711&idx=2&sn=8808427fec03e860ab3cd19a1994982f&chksm=8bbc858bbccb0c9d6d6891c820e3e4f34e62fd606f369341ec20ab34e10edf080389ebfe2fea&token=78874031&lang=zh_CN#rd',
    },
    {
        name: '刚刚，国家金融监督管理总局正式揭牌！',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652563398&idx=1&sn=d0580e0b04ec11dafff93c5b91569695&chksm=8bbc8eaabccb07bc37ec11f2818d4a2da9f5dff5499a7879c3698976822a2468d61b787134b3&token=78874031&lang=zh_CN#rd',
    },
    {
        name: '速看重点-《私募证券投资基金运作指引》起草说明',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652561519&idx=2&sn=d4870a8a5f0409c52fc1a5d58f5e8bef&chksm=8bbc8903bccb0015f69fe1f5a50047a2aeda6242bdb43bcb11a65dabaa9a6076621b36fd4d3c&token=1194533552&lang=zh_CN#rd',
    },
    {
        name: '协会通知｜关于就《私募证券投资基金运作指引（征求意见稿）》公开征求意见的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652561519&idx=1&sn=b28037371e5115733e5803cdce5b5986&chksm=8bbc8903bccb00153cb6d78d0b7c9babdc9c846f656a59854b4507f17bb5ede24c42d1594229&token=1194533552&lang=zh_CN#rd',
    },
    {
        name: '协会公告｜关于发布《私募基金管理人登记申请材料清单（2023年修订）》的公告',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652559273&idx=1&sn=108fec2e2cc602ab8d86a6083d86e1d4&chksm=8bbcbec5bccb37d35367bdb415c159940d9a6ebbcd4ea2f654592a1459b900f12f80e2ed03a6&token=1194533552&lang=zh_CN#rd',
    },
    {
        name: '协会转载｜全国股转系统挂牌审核',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652556960&idx=3&sn=60613dea5950674f743409f21947c9c7&chksm=8bbca7ccbccb2edaa8db8e7acba4ca334b5c0264f4120dcd43dfa0252024ea9a274a83cc4d1e&token=1820096898&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜关于发布《私募投资基金登记备案办法》的公告',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652552763&idx=2&sn=a76c5c1b2d3fde8d768f4cf0f4d4607c&chksm=8bbcd757bccb5e41a103a78056d65362aa4229cfe660c5866f0f91085cc329ddd13de0da2e75&token=1869733064&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜关于发布2023年度基金从业人员后续培训工作计划的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652552187&idx=3&sn=80d981dd40eb918f424bacd606736003&chksm=8bbcd297bccb5b817d5851b4ca3eca0fff7963745f107c5136b6b1af19d3287ef6ade136263d&token=1869733064&lang=zh_CN#rd'
    },
    {
        name: '证监会启动不动产私募投资基金试点 支持不动产市场平稳健康发展',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652551816&idx=4&sn=98182fde6f4af513ada2164669d46107&chksm=8bbcd3e4bccb5af29262000484948a6230ea4c1114b22591312f84a4b98b5eb5bfedb5a89d81&token=1869733064&lang=zh_CN#rd'
    },
    {
        name: '境外上市备案管理制度规则发布实施',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652551544&idx=4&sn=58b7c36d3e28eeff55b5055e09b265e1&chksm=8bbcd014bccb5902d6cb29753532102e3c1162a8ba2e223a7ceac0d75ce9b3c36fd4d449655c&token=1869733064&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜不动产私募投资基金试点备案指引（试行）',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652551816&idx=3&sn=fdad86f3529a9961a827c887998261af&chksm=8bbcd3e4bccb5af2d5d36a71ca3f2d2aeae8bad4733f22949da223e8c1a66b9919d6bdd263dd&token=1869733064&lang=zh_CN#rd'
    },
    {
        name: '全面实行股票发行注册制制度规则发布实施',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652551544&idx=3&sn=734c5a5d591b304fc350b04339160ba3&chksm=8bbcd014bccb59020fce8b27a678ce5edcb880c41fef33ef1f67b55452e0205067c7131410d4&token=1869733064&lang=zh_CN#rd'
    },
    {
        name: '协会公告｜2022年度基金业协会自律案件办理情况综述',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652549443&idx=2&sn=02072087d0ef77a4d03231194dcf15f9&chksm=8bbcd82fbccb5139c4ed1151499fa0c84be527447f54b2df3fbe2630e1c8cbed449612ff3944&token=1869733064&lang=zh_CN#rd'
    },
    {
        name: '中国证监会：全面实行股票发行注册制改革正式启动',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652548845&idx=2&sn=bfe8521ac860590ba9938612d5202e53&chksm=8bbcc781bccb4e97dc319904d157479cc5e287b9419d3b2833b5b0cdef77b0283a36a27c0dad&token=1869733064&lang=zh_CN#rd'
    },
    {
        name: '报名开启！中基协将于2月25-26日在北京、上海等45个城市举办基金从业资格考试',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652548493&idx=1&sn=d5468637bb86531d819e1fe77619f17d&chksm=8bbcc4e1bccb4df7a26aae3062c2b125727ff1d434d5c8f9a7f9f7fcb243abe324d95fddd763&token=87189730&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜关于发布《中国证券投资基金业协会举报工作办法（试行）》的公告',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652547089&idx=2&sn=49dba7cc547b941bd904b5137d6bf188&chksm=8bbcc17dbccb486b2a06d31b8abd64a9c2584e5324b23fea52f35be6550ec3bddd48033a2041&token=87189730&lang=zh_CN#rd'
    },
    {
        name: '证监会修订资管规定 加大对科创、中小微企业等支持力度',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652547058&idx=3&sn=1d9b6a9f8b698ce2898e90397dae8685&chksm=8bbcce9ebccb478816a8946aa31f8a086cd13d08ff12367363923418b21f38ba2e6f9251369d&token=87189730&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜关于发布《关于固定收益品种的估值处理标准》的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652546202&idx=3&sn=5158cc8f59bc41ddd9e5d7e074480592&chksm=8bbccdf6bccb44e0bde3a0bc67794e722f616b2eee11f968935f2ff5875bc03a78cc2da33258&token=87189730&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜中国证券投资基金业协会关于发布2023年度考试计划的公告',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652546202&idx=1&sn=2c9aee453ea35358074b4c41d1064f05&chksm=8bbccdf6bccb44e0647c58bb02f955074bafd432fbbbf8c2777caef266bdd73edf23322aa396&token=87189730&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜关于就《私募投资基金登记备案办法（征求意见稿）》及配套指引公开征求意见的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652545427&idx=2&sn=757985a580a997e2eaec795e6ffa9fba&chksm=8bbcc8ffbccb41e9318c6f18d1235b2d9fa443248cfe85e575d5d59a7a7eb63c9d46ba579162&token=1541026939&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜关于2022年远程培训课程上线的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652545176&idx=2&sn=41f37508c79bef63d6b722ed19bd0d62&chksm=8bbcc9f4bccb40e232cfef6f6913fa0634d30e91f3476653a6fcae3697de4892af6f0d1b5fba&token=1541026939&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜基金管理人绿色投资自评估报告（2022）',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652543796&idx=2&sn=b90adb7eebeef8765ffb66f67c2a4e0f&chksm=8bbcf258bccb7b4e203336307d6d8573d4f978e6946b8f64d2b56000772a2965ba2aba179a69&token=1541026939&lang=zh_CN#rd'
    },
    {
        name: '5分钟搞懂证监会说的REITs到底是个啥！',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652543615&idx=1&sn=0981e9479cef617f00bd4bbe5fdeb911&chksm=8bbcf313bccb7a05c8a495a5c2e296e1c904f48ca0d7b431caebc5c0dffc6affe9816947f64e&token=1541026939&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜关于发布2022年第三季度私募基金管理人会员信用信息报告的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652539665&idx=2&sn=caa12c74f825d6307b6676d91326adcd&chksm=8bbce27dbccb6b6b69a042387575b558acaaa6538d3aa7e06d7af0ec9ef87169497ef814dcba&token=1541026939&lang=zh_CN#rd'
    },
    {
        name: '中基协最新通知｜5点要求规范私募投研活动，防范内幕交易',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652538074&idx=1&sn=4d1a6615c078e428d1aae1cafba3c739&chksm=8bbcedb6bccb64a055e75d33e0079fbfd41d71093e7645bf62528356bb6510faf13470cb63b6&token=460848408&lang=zh_CN#rd'
    },
    {
        name: '2021年私募基金统计分析简报',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652534041&idx=2&sn=c0eab6cc83d7d8c6285f941ff7b265e7&chksm=8bbc1c75bccb9563b3b2a60a59fc34fd9e6f7d97a0f843e2c2055756da2b9c64b7a191fef363&token=1493089556&lang=zh_CN#rd'
    },
    {
        name: '协会要闻｜2022“保险资金+股权创投基金”私享会专题研讨交流活动成功举办',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652527092&idx=3&sn=7afc98a3ab3e3be19bb80bc8d13e244b&chksm=8bbc3098bccbb98ecc51e403bb576ab423a1aee0341ac778c53f07b9468a128fa53806dbc4bf&token=1196931154&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜关于发布私募基金备案案例公示的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652526253&idx=1&sn=f25ce36ad96e2390fd0b2dc261a39167&chksm=8bbc3fc1bccbb6d781e75f711ad0a77689f75f4be756a430567d29f948ca215d5e605697a62a&token=1196931154&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜严正声明',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652515472&idx=2&sn=e4bbb6a3aacc6241e8360663323dcd77&chksm=8bbc45fcbccbcceaecf13a683b0a2ad32172953ce288e97e8792f4cef6f7115aeae9b5721408&token=2099504437&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜关于发布2022年第一季度私募基金管理人会员信用信息报告的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652515472&idx=3&sn=d62f2f7b959cef6ef79593a22e569c2f&chksm=8bbc45fcbccbccea7af967d4f27eb72c3e3ced3a6d50a5a3304a6c347e060a0e29f98663812c&token=2099504437&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜关于举办2022年“保险资金+股权创投基金”专题研讨交流会活动的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652515472&idx=4&sn=f5330aa463aed38594945dd46daf9a41&chksm=8bbc45fcbccbcceaab0afd31c221cf6465fef27ef206d3195c355c9c13277edb4809c38d914d&token=2099504437&lang=zh_CN#rd'
    },
    {
        name: '协会通知｜关于举办私募股权投资基金税务要点培训的通知',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652511077&idx=2&sn=16f724408c7bc34a6674d3ef087ef9fb&chksm=8bbc7209bccbfb1f64bedc65cfaf176cbf388939f7a46fd13538cae8f5b04f6d98bba57ac291&token=503445930&lang=zh_CN#rd'
    },
    {
        name: '中基协最新通知｜超过12个月持续无在管产品的私募机构须提交专项法律意见书',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652510154&idx=1&sn=7e447758c5071d7fecfc2d9698154f90&chksm=8bbc7ea6bccbf7b04e8954c1d11118fbbc75b22da1045e264ed5ff454897c34a6161d25060fd&token=503445930&lang=zh_CN#rd'
    },
    {
        name: '重磅｜证监会启动私募股权创投基金向投资者实物分配股票试点 支持私募基金加大服务实体经济力度',
        link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652507587&idx=1&sn=a2b6c60d03baa05006f51ef1241ed9ff&chksm=8bbc64afbccbedb95f3cde3a46982190ce921bda8dd34137b43da185ced084e961f27858b6b6&token=503445930&lang=zh_CN#rd'
    }
]
// 公司新闻
export const companyNewsList = [
  {
    name: '@私募管理人，一月合规日历已送达',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652596625&idx=1&sn=0e8372a8d09d856bd81c8c604d99417f&chksm=8bbd00fdbcca89ebbac77c2311dc5a3643879923e65ff88eaf175a7a74e4c43443c975136579&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】外资入境篇之QFII完整篇',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652596623&idx=3&sn=9ee54b20cb91189301ff75912432f8b0&chksm=8bbd00e3bcca89f5eabaaedcc4010d756be9e3d4b3814e5a97be03c5f5b45b167051a265927b&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '盘点中基协近期备案高频反馈问题（第三期）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652596198&idx=1&sn=c897f7ed9374a913f1ea4f7906664b2a&chksm=8bbd0e8abcca879cd85a65312fff19ef65e7d55924416a017c656577d9268338e874bce5bf5d&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】基金出海篇之QDII完整篇',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652596198&idx=2&sn=23188c3cceb436f8ce46bcdbbd182c4b&chksm=8bbd0e8abcca879c2bd5273f97a679c31dacbf6f8c8d0c3c1ddf2465f581b8fe3e6b2818960b&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '干货｜近期私募管理人涉税问答汇总',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652595701&idx=1&sn=e9e9b1b73c664fe355135511b9f55a9f&chksm=8bbd0c99bcca858f4e448060577d27fa8d5ef80aca1dc4050f6b4493dc3bbead1e811f66150e&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】离岸基金篇之开曼（五）完结篇',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652595701&idx=2&sn=d5414c77d8d135e5df4495aa1e4d287f&chksm=8bbd0c99bcca858fae042d6814683be492b372849d3c1cf582b9060dbd3e82cf6afc2d3b1f86&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】离岸基金篇之开曼（四）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652595277&idx=3&sn=15363cf9946d379ba1ece3ab43e23c1f&chksm=8bbd0d21bcca84375ba52615b818fd66f232c3ee37f3db4a841be5802ab109f2203fe5b65b98&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '元年解读｜私募投资基金监督管理办法（征求意见稿）的变化与重点内容（下）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652595021&idx=1&sn=5e64008f80715a6aa06759f17992271c&chksm=8bbd0a21bcca8337699a807941d0e5412499b5f4dfeecf524481f406963ff20139ac5eabfd8e&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '元年解读｜私募投资基金监督管理办法（征求意见稿）的变化与重点内容（上）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652594283&idx=1&sn=47c8fa773500781aaccc9490fb1d03e4&chksm=8bbd0907bcca8011d80cfa828bc3e140c4fb7463b61987f0e65a9bb126469c4c9f105fdfd5bf&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】离岸基金篇之开曼（三）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652594283&idx=2&sn=cb7fcfe4831e76de38888f9aca09a2b3&chksm=8bbd0907bcca80119fc01e4248e3e3e57acdca6a02fe21b3d933b40875de2a511a9f9d0dfffc&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '附免费pdf｜11月元年红宝书上线！',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652588923&idx=1&sn=2de16b928ebaab529f80c1c5983f20c6&chksm=8bbd2217bccaab01052b97221d435228d35b0befdd685387be34a4665f6e7fc7311f641253d0&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '要点速览｜关于就《私募投资基金监督管理办法（征求意见稿）》公开征求意见的通知',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652588838&idx=1&sn=da03dbdcc96c6ef6f1310cb46e25998f&chksm=8bbd224abccaab5c0ede5042590cb68256b005607b4ea53b713ac2022402fbabeb7971d6d6ad&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '官宣！元年金服已推出美元基金行政管理服务',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652588767&idx=1&sn=af53e6af97b9c90d0b7e408c4826ef4b&chksm=8bbd23b3bccaaaa53a38ddbad2d34e613ba3e733b5c47c612d61b2f4c055bbe8a3c4fd59d002&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '上海金融法院、上海证监局联合发布十件涉私募基金典型案例',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652588635&idx=1&sn=9ed5d87c32ffc92c307ea3ff3367fceb&chksm=8bbd2337bccaaa21f7f0cb9da3af2020c22420486552b5419b7ddf2ae7d79f82916889e69d2a&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '月度盘点｜私募基金单月备案数&管理人登记数回升（2023.11）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652588635&idx=2&sn=5faea62cdf4f4e3bcf0e9c1127c7d8bc&chksm=8bbd2337bccaaa216a7ad649482d85915ef2e05efcb6de8fe4033c4ac3aece634890ccea4046&token=360890238&lang=zh_CN&poc_token=HFZrk2WjleKdcyCS0iAtioY6QSbWaCDsaMjkPcky',
  },
  {
    name: '【跨境观察】离岸基金篇之开曼（二）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652588635&idx=3&sn=0437c0b8d90a48bc605447d88b45148e&chksm=8bbd2337bccaaa2143e7476891ce46e01cc0e066a4123930e3a4eac9bbfec57d7c1ff1bfadb1&token=360890238&lang=zh_CN#rd',
  },
  {
    name: '【新规速递】一图厘清获取基金从业资格的方式',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652588296&idx=2&sn=73e81d5ed1009f480fb053704e152a38&chksm=8bbd2064bccaa972b458f753aad645d7d9369f14ad2a6d1595b234358fa3793dd2c941eed0de&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '基金从业统考来袭！12月16日考试，今天开始报名！',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652588056&idx=1&sn=13938c9979836a0e9e59e80ce1ab483f&chksm=8bbd2174bccaa86244f8e5a4ea3292d3948f9f767a69b6ee4c8831c9555d22883c48446c165f&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '逐条对比新旧《基金从业人员管理规则》',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652588042&idx=1&sn=ee468abf5cb3a374692198ead7b706bb&chksm=8bbd2166bccaa87023ca579d8b307621ba951ad8b2b1b35b49f95ad0e25c75957d58dcdd2267&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】离岸基金篇之开曼（一）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652588042&idx=2&sn=c4f42a260e2c5ce3bb942d4bc4edfe28&chksm=8bbd2166bccaa8708db398addefef1fbbb93e0fa833bcb0b9b2b6c0b73571974f5ca6e451d62&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '划重点！基金从业人员“学历认定+应知应会考核”的实施细则来了！',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652586939&idx=1&sn=d9004a9cf0402e599f7a102fccdfac12&chksm=8bbd2ad7bccaa3c1c89375731007e23d83b850709a0e5ea792ccfe718c63d479018eb6098570&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '盘点中基协近期备案高频反馈问题（第二期）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652585760&idx=1&sn=87421f8f27b73362446b5f467ebf5340&chksm=8bbd564cbccadf5a385bfc48170978d0fc7cbf5cdaf32c1406fd985ede2ccb494909f36099cd&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】基金出海篇之QDLP（七）完结篇',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652585760&idx=2&sn=14b58e938e0a158d713fdcc31fa99888&chksm=8bbd564cbccadf5a5d5571bf77efa540b996d4bc1c033e9b727d1572a6ef5f6fabc86c4acfd6&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '干货｜近期私募管理人基金运营问答汇总',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652585737&idx=1&sn=b289a047d6249e67ff480a56807b770c&chksm=8bbd5665bccadf73773de56c014de0d6125cf4f287f8fc693107c176188999f559b95379ce92&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '合伙企业20个涉税热难点问题，现在统一回复',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652585737&idx=2&sn=b861660fea3379866b013e5f33b22710&chksm=8bbd5665bccadf735fef2983f22eccbb70d677d016836f1d8cff9fb799b5cfff867174fb07a2&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '最新！私募生存状况大调查',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652585514&idx=1&sn=548c2f7029fad9c41bf7cb6c4fabea7e&chksm=8bbd5746bccade50a8bad7dd65fb77e98c5ec9a28bb5382c2ca8da7746c844c8f4beff212262&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】基金出海篇之QDLP（六）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652585514&idx=2&sn=2b8346151ae53478adf656c7e8f3474a&chksm=8bbd5746bccade502369bf690459637be5d44920385354d5285248610a2bf36fd6726b25cde3&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】基金出海篇之QDLP（五）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652584345&idx=3&sn=60331fe13b030128766aac11ea157a64&chksm=8bbd50f5bccad9e3a738fcfa3baf23b45a0dc7c6e2f63fa27b20a2f74f97141e24d204540987&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '税务局官方：企业所得税60个热点问题汇总（2023年1-10月）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652584102&idx=1&sn=bac04c7ae1d13e146e772939e1b8a71c&chksm=8bbd51cabccad8dc65efc6575c6e929c3e25d6dc58f4622bc3d17b2085ac2ee2782ace263941&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '一文看私募基金管理费的收取方式',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652583911&idx=1&sn=cd3afa4161bda6d89e6efd300607edce&chksm=8bbd5e8bbccad79d21a382d0c677937d8d16018d7c1549941c7ede89e9218044a09277dff88f&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】基金出海篇之QDLP（四）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652583911&idx=2&sn=1bc45f0beb70c55c1188824becddf7f7&chksm=8bbd5e8bbccad79d14ca1586333d8aba9665501cb59549b8a0848bc47f0ed10b230b45a3b9ab&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '附免费pdf｜10月元年红宝书上线！',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652583419&idx=1&sn=dd720c6fdeedbf22bdd81217fd4bcad7&chksm=8bbd5c97bccad581a1a7795356101b28391ebbfa9b0259e2e930a8d301d20a5c58493254347c&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】基金出海篇之QDLP（三）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652583419&idx=2&sn=92381c129db74d90126db2d814787109&chksm=8bbd5c97bccad581c3711ea3ed878b7c889c73423a7eb5297784e372d7e651f8400b5b127218&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '私募基金常见陷阱：案例探讨、法律观点与管理建议',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652583266&idx=1&sn=4524070a2b600e3b3f08df669a4b0d68&chksm=8bbd5c0ebccad5186de8a2b1e83908117971e4d6fa64ca27dfce4605cd4cd365ca2a10da4988&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '干货｜近期私募管理人日常合规运营Q&A',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652583202&idx=1&sn=bf4192c1e265d28e16c9c0b0b3bb9159&chksm=8bbd5c4ebccad558679bd280ddde7875512a2b7274685cf031bf36dcfebab9cee97ef5b55ea9&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '创新低！私募基金单月备案数（2023.10）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652582904&idx=1&sn=da0acb813460d910eaaa05b1ce6ecff6&chksm=8bbd5a94bccad382ae96f3906aceda02e770c72c05b0edbf9f1720ffd144277b2ddf4413c409&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '最新数据｜近三年中基协私募管理人数据统计（图文版）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652582904&idx=2&sn=980e877cee292acd049af0949d5ce228&chksm=8bbd5a94bccad38221a30dda20b79ba877869518e76ca4d927fab2ea3bcf2a45ed3dcf3a6699&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '元年金服扩招啦！私募基金会计、基金运营助理、海外私募合规、产品运营等',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652582748&idx=2&sn=2b1b524bbb6f671a4dab9fe8f785af9b&chksm=8bbd5a30bccad326a18860c2b6da019abde7f0f95beedf9b40680e0cec4d1b092baee90953c3&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '@私募管理人，十一月合规日历已送达',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652582748&idx=3&sn=559226d660dfeaaae1979c136dcb287c&chksm=8bbd5a30bccad3264527c33d79ea89eae56c95ba7160121744ea4620270621fc3d302e2acb48&token=360689050&lang=zh_CN#rd',
  },
  {
    name: '图解｜中基协近三年私募管理人处罚/注销/登记数据',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652582627&idx=1&sn=ab0b964bb5d3ff041275ff6f49869c3e&chksm=8bbd5b8fbccad2998c9e4706c6c9d29f694f2cc3e2b6bb68c3a90ca56b5384888170fbb75c7b&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】基金出海篇之QDLP（二）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652582627&idx=2&sn=a317b7084faa28225872e1b15ff212d2&chksm=8bbd5b8fbccad2999246058b6fe7dc73cac399dbe70daa62b8c927b3addb8af8931b628444f1&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '基金从业考试来袭！11月8日将在北上广深举办预约式考试，今天开始报名！',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652582568&idx=1&sn=b078ca96d68d40dae83a1db453457312&chksm=8bbd5bc4bccad2d258ffbfa5f750b63432def1e87266dbaa385ba04af53f9f7c639e853c6131&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '新规之下，私募股权机构投资负责人、风控负责人有何任职要求？',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652581800&idx=1&sn=49a7b22ab7124b46ec80889ec5813e8b&chksm=8bbd46c4bccacfd277ed1944ba46291395d88f704a6426be0df5f0f527c684e4df7885996cf2&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '私募管理人请注意！中基协对控股股东的最新要求',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652581778&idx=1&sn=9fe15fb7b8912b9e1b8c11f9e5b38d17&chksm=8bbd46febccacfe89ce38001772bdb156cd0c555821cc098747981891261dc22f59b56781aca&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】基金出海篇之QDLP（一）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652581778&idx=2&sn=580bbf9a21e219da550d565238cf5c4c&chksm=8bbd46febccacfe8f22aa241eec260d46b738966ef4d19f6fd1b52401411403fe793d101c6dc&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '新规之下，私募证券机构投资负责人、风控负责人有何任职要求？',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652581720&idx=1&sn=4019b4d709c0707821b047e7380f4d48&chksm=8bbd4634bccacf22e7a60dc15e76540698666247bdcfb3c361d79913abed39d5ccaedac7d5be&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '附免费pdf｜9月元年红宝书上线！',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652581195&idx=1&sn=a607188803f8b45ba8b7c884de03b569&chksm=8bbd4427bccacd3117ed08b3647a3260d37946d9bf1d6c2281650e3d2f717acf7e60d4a920be&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】外资入境篇之QFLP（六）完结篇',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652581195&idx=2&sn=2049d57dbf6c0a8a380e7b0b4cc73722&chksm=8bbd4427bccacd31b0a34b02c8160435bb3872577757ce2855132846f53d71f160457c57d195&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '干货｜近期私募管理人登记问答汇总',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652580837&idx=1&sn=0f7b3a38f4d99aca0a894dd5c2b3527c&chksm=8bbd4289bccacb9f775dd3ad448e26cdae13a01b8d561e94c455204ea80be5dbb419e09d411c&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '私募投资基金变更管理人指引规则解读',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652580684&idx=1&sn=8613956ace4d0ea1528c2718acce3f05&chksm=8bbd4220bccacb360c43984680c9e5fbcd960a9f4f9b80b67bfa7c7c4d89249ced47ace17f1e&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '私募股权、创业投资基金备案指引规则对照表',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652580676&idx=1&sn=8a4f16bcdc5783229c466e73cb576608&chksm=8bbd4228bccacb3eff71147d8f4276018281848c4b30d1d30e7b7978d0463ec6ac943b2a99c4&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '私募证券投资基金备案指引规则对照表',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652580654&idx=1&sn=993a1d2c188adca7f1af886b12e8037b&chksm=8bbd4242bccacb540e531a905dadecbac5b79bb9c4341fcc60a334581dcf42624d486569b740&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '九月盘点｜私募基金单月备案数走低，管理人登记数略微回升',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652580479&idx=1&sn=6b83f2cb8000c073c2f3ce24b180577f&chksm=8bbd4313bccaca0506f492751197a84cec24fd1f3372f25f1b5d7b27179246f82c8980be7802&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】外资入境篇之QFLP（五）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652580479&idx=3&sn=723dabf47933fb6860e7bf545ca954b9&chksm=8bbd4313bccaca05e57e6dba5de430e64c9fcda685b4afbabefb7161ff6e84e68126c825eba9&token=708883074&lang=zh_CN#rd',
  },
  {
    name: '@私募管理人，十月合规日历已送达',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652580289&idx=1&sn=6c782aa85d0663e8910fb731be96e1ce&chksm=8bbd40adbccac9bb52c83a8b9cc1460d66cc51d0330d930497f66d760a373c1a8aad7478fc5d&token=166763084&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】外资入境篇之QFLP（四）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652580169&idx=3&sn=f97be36000f2476ce4f6bc0c34e1b05c&chksm=8bbd4025bccac933c45b4771897ae92f0a5428b29024bec50d80e9e3914045aa8828123c86df&token=166763084&lang=zh_CN#rd',
  },
  {
    name: '上海：允许在上海自贸试验区及临港新片区内注册的企业、工作或生活的个人购买跨境保险、投资等金融服务',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652579988&idx=2&sn=ef7451bc058c55dd95139b86f650c0d4&chksm=8bbd41f8bccac8ee51849979f1d7abad6a25dab5a7f36220ef5325af93466e3539ab8dd5fe72&token=166763084&lang=zh_CN#rd',
  },
  {
    name: '私募基金和私募基金管理人要交哪些税？',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652579977&idx=1&sn=201291a57f7d35765b8955f85adc1696&chksm=8bbd41e5bccac8f3871b52852b707b28c072b431523429e4b2049d989b3591cbcc30982c861d&token=166763084&lang=zh_CN#rd',
  },
  {
    name: '必看｜近期私募基金备案问答汇总',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652579898&idx=1&sn=816be170680c712cdb5cac36e7109644&chksm=8bbd4156bccac84027c3c1d9279a9f767a6926985452a77df005bf54768f91e8545520f80b8c&token=166763084&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】外资入境篇之QFLP（三）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652579898&idx=2&sn=7de3d600fb0af09f0928a19c18ab2a51&chksm=8bbd4156bccac840c3eb7661855e57cf64c88060ddceafbf1d64d4971c8815acdfc08ef7a8f2&token=166763084&lang=zh_CN#rd',
  },
  {
    name: '附pdf｜元年红宝书首期上线！',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652579727&idx=1&sn=22d24752e7c459f8cd3dcbc019bb00bf&chksm=8bbd4ee3bccac7f557e9d04668d85ebb446692e8f895a82359fce1b10ab9f180d1e4799b591e&token=166763084&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】外资入境篇之QFLP（二）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652579086&idx=2&sn=26c81155a06300a90c1cc64ecfc00c6d&chksm=8bbd4c62bccac574602000ef60cd44f39f54b6a7d06421b6f81811e6d2691e235cab2c45c52f&token=166763084&lang=zh_CN&poc_token=HBRlI2Wjap2KACZfRt4waksdPsID8ZvBKNCXFisu',
  },
  {
    name: '万字梳理｜私募基金清算系列问题（概念、清算事由、清算流程、强制清算、是否保留市场登记载体、其他退出方式等实务问题）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652579014&idx=1&sn=880beeee505f0997c46413b40a1cf0c9&chksm=8bbd4daabccac4bc42c5908bbf042fa155942e7f1209a9a9626d2b70d167e33d34af58eb3617&token=166763084&lang=zh_CN#rd',
  },
  {
    name: '最新｜创业投资基金发改委备案的关注要点',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652579002&idx=1&sn=b449e3940c9b35f8a036d8785d901e05&chksm=8bbd4dd6bccac4c048693eb0d104a149148dab304b1ee8662de9d70049cd8f76a9ad6204d0e2&token=166763084&lang=zh_CN#rd',
  },
  {
    name: '私募基金份额转让合规要点',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652578862&idx=1&sn=0548e3bc28d06f05ff8fac4a39330361&chksm=8bbd4d42bccac45441f83318021665f4cd2b8eb84d2c8576dde819dd2cb8b65bae8b82622643&token=166763084&lang=zh_CN#rd',
  },
  {
    name: '独家整理｜全国QFLP制度政策梳理',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652578283&idx=2&sn=f2aefa068cfef640db33afe6252813ec&chksm=8bbd4887bccac191b79e9e8f9ddd6345acaddd6ea51a8432657a21a27b97658df983843dc374&token=166763084&lang=zh_CN#rd',
  },
  {
    name: '盘点4个中基协近期备案高频反馈问题',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652578273&idx=1&sn=f2c171cc72e666a798a3a4a685bd9127&chksm=8bbd488dbccac19b86fb5344b680a357dd6b89e5a6dd98ec0add902a3fe926a2a4173a49f576&token=166763084&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】外资入境篇之QFLP（一）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652578273&idx=2&sn=44b1ab9cd9c0810004e10282a2144163&chksm=8bbd488dbccac19b8059d25611aaa70044e701280434c33e38dc40dd70e63839f0f4f58378f7&token=166763084&lang=zh_CN#rd',
  },
  {
    name: '@私募管理人，九月合规日历已送达',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652577976&idx=1&sn=74d68cdc6f094a459b8557133f828a39&chksm=8bbd49d4bccac0c2beff6663f0b894f79563bda2c4fcbbedaf0142677060a62281fe9530a0a1&token=166763084&lang=zh_CN#rd'
  },
  {
    name: '认缴出资或需5年内实缴到位-公司法第三次审议稿速看',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652576055&idx=3&sn=c8f562c25f29fd9b9a0c03efce863964&chksm=8bbd705bbccaf94d7c0813245bb2efc5da5d04962b98db8799e5f3a15b00c30aae63b34e8932&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】离岸基金篇之香港（八）完结篇',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652575796&idx=2&sn=60d7a85fa3e2d49421df75baab8d08d9&chksm=8bbd7158bccaf84efa14a24ed545655ea01968dffd522f7e06041d07b2b41d50eee5a07e3472&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '重大利好！创投基金个人LP单一核算20%税收政策执行至2027年12月31日',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652575323&idx=1&sn=e21f3070d399d27db36c3ea6f660ea9d&chksm=8bbd7f37bccaf6213fb86846cb8865dd19125e485bc130f3d84ae36e6734f8ee54b26f6d9ba3&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】离岸基金篇之香港（七）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652575323&idx=3&sn=554050be92abab726d0c085b0e5965cb&chksm=8bbd7f37bccaf621329fda4a1304880895fd85cc60c9ac9da0868e45b8497dcd1877dcb9c641&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '干货！如何选择专业的代理记账公司',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652575044&idx=2&sn=2a22c5b0255aaf1bed83d6933f75d4c5&chksm=8bbd7c28bccaf53e8d3be27ff016638ea78bc9ca7872b093395753aa6a37aa3eb2417fb8362f&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】离岸基金篇之香港（六）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652574328&idx=2&sn=310717bbfc636f3486ccd3c5e256bb57&chksm=8bbd7b14bccaf2021f71d8f556b2a308653f54861673dd00ccc20055eca9e27c5e5bcace3027&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '独家整理｜全国QFLP制度政策梳理',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652574041&idx=2&sn=1c355a68042695a00393f62c2eb68fff&chksm=8bbd7835bccaf123c1408478d483ef2699acfbed1467c68fd37abe06ca41682a2937c6e18c0e&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '协会最新反馈！私募风控负责人管理规模<5000万，不认可？',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652573902&idx=2&sn=1d68e71cdda9a282bdc7c81e7dbfc9ba&chksm=8bbd79a2bccaf0b44c73c238c68855b5b780a3e96406c840bbd340cba10232343f6d5406ff47&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】离岸基金篇之香港（五）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652573769&idx=2&sn=783fc98381d9654366d871cad517d347&chksm=8bbd7925bccaf033febc66e650988aaaefbd8c4accd804947b57159afc575e5a082ac5050f7e&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '不同私募缴税差距如此之大？一文理清私募基金税务问题！',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652573657&idx=1&sn=154a149cbcdfe8fb3e6fbf8fcef6636d&chksm=8bbd66b5bccaefa364852f1ed1c1542b6c6d20ff043d3b7e44fbafb3261b3ae1f9e4320a087f&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '最新私募基金管理人登记流程和登记要点',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652573239&idx=1&sn=f9279cdd30801c5780270bed77cfbbef&chksm=8bbd675bbccaee4d07cd0eb454ccf1507bfaf5b3ce71ba64fec88bdb2217c2897627144cebef&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '财政部 税务总局：延续小规模纳税人增值税减免政策至2027年底',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652573239&idx=2&sn=0f422f0f6db0157f0b333583456c58c2&chksm=8bbd675bbccaee4d03535d91e3e3ecb802ab3d45b6b467ee96f513cb453679113da30366dbfe&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '月度盘点｜私募基金单月备案数走低&管理人登记数略有回升（2023.07）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652572739&idx=1&sn=7107dfb7bdbd34dfb633921af72d1e8e&chksm=8bbd652fbccaec3961bb979d07cc626e37cdfb6566c1e99c44c44ad8a1dba8c5e8db4fd34d3f&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】离岸基金篇之香港（四）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652572558&idx=3&sn=451d76397b5d318145cc053bcbc0561a&chksm=8bbd62e2bccaebf42ce0379c1b71af6a28d97cbd0fa91dae228be533a5f7d798f0500a1660e0&token=918575319&lang=zh_CN#rd',
  },
  {
    name: '2023年上半年私募处罚案例汇总（中基协、证监局）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652572317&idx=1&sn=abd6d6614bc34ce93e4bca557b2fe6cc&chksm=8bbd63f1bccaeae75a00c8a2d53d42d0c6793c8b501ba8873b089ab7fa7f6b82ad6518bc28aa&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '老板说，2天内我要让证券管理人都能看到这篇',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652572066&idx=2&sn=1a8c12da674ccb032bd1cefc5303ccc3&chksm=8bbd60cebccae9d88025c67e247571006030b299e7dc0609594a75effdad93ed3068eb44e814&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '基金小镇运营模式研究｜附最新政策梳理',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652572066&idx=1&sn=b26145154632421d8fcf92dcaac41daa&chksm=8bbd60cebccae9d8651911606faec614be4e479067eee61f5e66efa62fcdf303a65f0b3db0e1&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】离岸基金篇之香港（三）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652571871&idx=2&sn=af62b42cf53299f9612944c50fb165bd&chksm=8bbd61b3bccae8a52ffc42045e2b9dcbcc7722303f8edc8c08598979b4708aaf059a7d8326c0&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '真相了！托管和募集监督机构拒绝你的理由',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652571357&idx=2&sn=efd8a161aed045b26b01ac758e1c7fe1&chksm=8bbd6fb1bccae6a7fea65a2ee6570b61235d44fe663c0ee0f0b5d1605191c6a92f8b46ba799a&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '没开玩笑，这是一则寻“人”启事！',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652571306&idx=1&sn=54314dfef16d9cb4afe76efd673593d5&chksm=8bbd6fc6bccae6d0f5feb426193c4045eca15bf67e54cfff09caf3a1803a567ed796855fdda2&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '独家整理｜全国QDLP/QDIE制度政策梳理',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652571015&idx=1&sn=0724699b1c5f93bed1edce735d649160&chksm=8bbd6cebbccae5fdfc8d8c81d445942eb8b20fcc9adcb217e226b2ca387ff5287b801e20b0d5&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】离岸基金篇之香港（一）',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652570744&idx=2&sn=16c7016900626db6e319435cfa09f84f&chksm=8bbd6d14bccae4020d45c2ff87fdfa0992e3fe3520b0ffeee5f3068af700e73baf33347d82f7&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '敲黑板！以下行为违法成本将大幅提高',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652570744&idx=1&sn=97e54fb58874c818467bc74f95e1e85f&chksm=8bbd6d14bccae4029210af2e565ad122b451bd706f00e344312469ab9a43b4affbc449d24c3a&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '2023年上半年私募数据盘点',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652570496&idx=1&sn=9818dc6e34d5825f35d8ffef1239ad36&chksm=8bbd6aecbccae3fa61aecdf0f9087ffb6c3acf91e5c2a183e9538e52fb869ce9a0f8cdb97112&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '管理人必看｜私募管理人信批事项汇总，如何避免信息报送异常',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652570496&idx=2&sn=b52f66cf8e0c5450cbab73bc83a02534&chksm=8bbd6aecbccae3facc72fda5c2d021d3680b34016335b59139e0cf64b8dd7fce2d0e8f1f2c94&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '要点速览｜《私募投资基金监督管理条例》',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652570419&idx=2&sn=79e78759077933723691a6024b3f4e36&chksm=8bbd6a5fbccae34941c369e901a333827a71ab627f6e53c42f90a4320931e7c8a4d8b21d6f79&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '重磅！《私募投资基金监督管理条例》（国令第762号）正式发布',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652570419&idx=1&sn=f159c8cc04cffdf93c4adafe042cccae&chksm=8bbd6a5fbccae34999460a95cb61031272ffa7ce297674f840c9827587ff5b9248bce6960935&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '官方解答｜备案办法中规定的“经中国证监会备案的会计师事务所”具体指哪些机构？',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652570355&idx=3&sn=82e747e7b256d8e2da3f72001b950e5f&chksm=8bbd6b9fbccae289678df2077100f80404ae528c7733ff9187a512877863701618008bbac4ca&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '收藏｜基金从业人员相关问题解答',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652570239&idx=1&sn=04480188f9c12a010c1e8e776ea1c902&chksm=8bbd6b13bccae205175d956d2998c0393a8bfad1e24dcb6d00b946a789b2cc0b5e5700f5aa2f&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '【跨境观察】基金出海篇之ODI（八）完结篇',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652570122&idx=3&sn=ea91cbe48eb56661ee1a760b2bee76c6&chksm=8bbd6b66bccae27068d587efffdb4daf30fae118fd9a4455c9d8f1f61ee3966a0b3861b7c5f5&token=1753598493&lang=zh_CN#rd',
  },
  {
    name: '划重点！私募基金监管报表报送指南',
    link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652569913&idx=2&sn=c22d64adf7c5777d4d30039377776417&chksm=8bbd6855bccae143fd140a1876b273f2439c66675da4ffc7b6af5e4e721aa175fd9d5d6d2c83&token=1753598493&lang=zh_CN#rd',
  },
  {
      name: '@私募管理人，七月合规日历已送达',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652569886&idx=1&sn=c38c9d0a53a18dcd099822e097d38f34&chksm=8bbd6872bccae164c741391b035cbfdb2264dfee05fad8420b0842d702698e322aff51d74172&token=983337416&lang=zh_CN#rd',
  },
  {
      name: '海南QDLP开放第三批试点资格',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652569886&idx=2&sn=1d7268d313da914f08bc92be6dde7b41&chksm=8bbd6872bccae1643895fc7e52cfe5bf0776fc87a4af177290a6d0a21a3c7a57fecd421f6c9a&token=983337416&lang=zh_CN#rd',
  },
  {
      name: '近期备案热门反馈｜新规后单一标的私募股权基金风险揭示书',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652569819&idx=2&sn=e20bb6ff652e71ad40e9f1f06fbd4722&chksm=8bbd69b7bccae0a17daaa47824601d3f75d77709c29f16ed853e4c2d4ea04b2d63491a655825&token=983337416&lang=zh_CN#rd',
  },
  {
      name: '必备收藏｜私募股权基金运营都要做哪些事？',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652569424&idx=1&sn=1eec1b1a0a9c6ab9698c1e63182a906c&chksm=8bbc963cbccb1f2a58db4504b97fe90a201119c624f31603bce5f3227370d84a0f0cae94b526&token=983337416&lang=zh_CN#rd',
  },
  {
      name: '【跨境观察】基金出海篇之ODI（七）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652569206&idx=2&sn=d53a7b91fab1aef561bb3048e649354c&chksm=8bbc971abccb1e0c1e49345a34b4a1506468c00c446825fb35821b4099f686b4938382e3dd58&token=983337416&lang=zh_CN#rd',
  },
  {
      name: '【跨境观察】基金出海篇之ODI（六）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652569059&idx=3&sn=7284efe554b0892ebf894e3fc014cb00&chksm=8bbc948fbccb1d990d6fbb4c8b7e3c2856280696e9072386e88f3676e1055fd7675d7c006b21&token=983337416&lang=zh_CN#rd',
  },
  {
      name: '划重点 | 《私募投资基金监督管理条例(草案)》通过',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652568882&idx=1&sn=4755707d5ae1acd70305a6f06d940718&chksm=8bbc945ebccb1d4802d66e7101b80d2e26fd0863c4cebee030f357f854c4b61ae78b2ba4ab7c&token=983337416&lang=zh_CN#rd',
  },
  {
      name: '新规后私募基金管理人登记流程和登记要点',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652568648&idx=1&sn=7ccc045fb2cb573379421d19c67cd9e6&chksm=8bbc9524bccb1c3251e7c08ea2b1ca521f8433cb4a7ecfaf87ea15dec297ce3fd5df98015ad1&token=983337416&lang=zh_CN#rd',
  },
  {
      name: '【跨境观察】基金出海篇之ODI（五）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652568447&idx=2&sn=aae4a1542a9d78ac3a44fa755327c78c&chksm=8bbc9213bccb1b05d7cf9d89e0042b22d8e4f601767f871ebf31a02ed72209177d3900a339bb&token=983337416&lang=zh_CN#rd',
  },
  {
      name: '独家整理｜全国基金小镇注册持股平台及投资类机构政策梳理（7）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652568039&idx=3&sn=64093e26c08b969b52633c66fcb3b16e&chksm=8bbc908bbccb199d88d2a87e8974ff2b49b295e63cd467cbe165423fe6e6b2905dacafab06bb&token=983337416&lang=zh_CN#rd',
  },
  {
      name: '【跨境观察】基金出海篇之ODI（四）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652567902&idx=3&sn=85c78c2dcd892c675181aec32f580bf9&chksm=8bbc9032bccb1924f332c7dc69502936e9969cdb5d857c4e9e4f5086277bb727776d07172161&token=983337416&lang=zh_CN#rd',
  },
  {
      name: '2分钟通过数字速看《私募投资基金登记备案办法》',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652566509&idx=1&sn=2f4965a8c4c72e899b86d947e896326b&chksm=8bbc9a81bccb13973981dffa439e4f4f12107418ab9392aaf0aafc3b69943373022eb5d40596&token=983337416&lang=zh_CN#rd',
  },
  {
      name: '月度盘点｜私募基金单月备案&管理人登记双遇冷（2023.05）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652566509&idx=2&sn=45b9c473e5f148288f475d049d080a81&chksm=8bbc9a81bccb139727d9b6705d1627db265c55d0f730b1eede343ccef6bbee6ec162f7f2d5b8&token=983337416&lang=zh_CN#rd',
  },
  {
      name: '【跨境观察】基金出海篇之ODI（三）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652564711&idx=3&sn=76bdbc01e07d7902bf0e664fb0fe2e35&chksm=8bbc858bbccb0c9d12c0121e1d8874b5cb1b0585255463d2b67b9a70e29e3b23031004ba7daf&token=78874031&lang=zh_CN#rd',
  },
  {
      name: '梳理｜私募基金管理费的收取方式',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652564508&idx=1&sn=4eebc74eeee396935d82a697399c997d&chksm=8bbc8570bccb0c666d5dbd1a82c94d308e07d38e261d50ce90a47a2e1c201892fef320b44dd7&token=78874031&lang=zh_CN#rd',
  },
  {
      name: '终于揭晓！新规实施后首家通过登记的私募基金管理人花落谁家',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652564448&idx=1&sn=8685c1827c1d238f64ce02c69bdb3605&chksm=8bbc828cbccb0b9a2c3cf3eb1fbf4c9ea0fd99117913d161bee40d1774e4f2ada2f4b2d7daef&token=78874031&lang=zh_CN#rd',    
  },
  {
      name: '值得收藏！中基协关于新规的154个问答（2023年5月更新）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652564278&idx=2&sn=bacb214807e609f396fac716b79584e3&chksm=8bbc825abccb0b4c9a5414715780493577d5812a356f2d172f1afbf6ffcbe7e9c5394616e65f&token=78874031&lang=zh_CN#rd',
  },
  {
      name: '独家整理｜全国基金小镇注册持股平台及投资类机构政策梳理（6）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652563875&idx=3&sn=9b37b101003ee47777292d888ac41cc1&chksm=8bbc80cfbccb09d9d728219fad1fa5fd29bfacb886a813ead48a56fe5fde4be19df01f33d78c&token=78874031&lang=zh_CN#rd',
  },
  {
      name: '【跨境观察】基金出海篇之ODI（二）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652563832&idx=2&sn=516da9a3d1de4d623a8f893630e9200b&chksm=8bbc8014bccb090260903d2cd4d8d4601ba9b9987d02d9f5b62fb1543a41198ee874bf883075&token=78874031&lang=zh_CN#rd',    
  },
  {
      name: '最新｜创业投资基金发改委备案的关注要点',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652563832&idx=1&sn=a9a05e091d6a0b5e8e9b3aee84948611&chksm=8bbc8014bccb090220a920c8fdba1db5dbf362811f1363ebc89fb609a495066fab6fd79e1802&token=78874031&lang=zh_CN#rd',
  },
  {
      name: '独家整理｜全国基金小镇注册持股平台及投资类机构政策梳理（5）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652563190&idx=2&sn=de710960b1d59c4ffae02fb7382102dd&chksm=8bbc8f9abccb068ca0d6ed530ca4de9bd104a521d0447b47ba6983e5eca0ce69a695e792f235&token=78874031&lang=zh_CN#rd',
  },
  {
      name: '【跨境观察】基金出海篇之ODI（一）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652563130&idx=2&sn=338a1f43fe6f909a62876a1a9acb79c3&chksm=8bbc8fd6bccb06c0cd019f2f607298f5bda8fec8cc05ff6dfdc0d73dc6550c906b798db6900e&token=78874031&lang=zh_CN#rd',    
  },
  {
      name: '顺势而上 力辟新局——元年金服香港有限公司正式成立',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652563130&idx=1&sn=0eb1cb1dfab44cc7f2966e403064d6ef&chksm=8bbc8fd6bccb06c064218c4288d1d3659d339978b9136dfefd494706758e43c0641a8166dfa7&token=78874031&lang=zh_CN#rd',
  },
  {
      name: 'CRS的申报要点及常见问题解答',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652563088&idx=1&sn=9f21d46e8ec58f315359d47ef2e4aa0a&chksm=8bbc8ffcbccb06ea44948da8bd8e9ae2019706c5c3081048228ff0f38ae862faab149f09932c&token=78874031&lang=zh_CN#rd',
  },
  {
      name: '独家整理｜全国基金小镇注册持股平台及投资类机构政策梳理（4）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652562768&idx=2&sn=07584ab5fc2f3a35778f6aa5965dfdb4&chksm=8bbc8c3cbccb052acc5025ad7d3040ba5c1235160d3d1055f229c8411ac56e4552a24642d99f&token=78874031&lang=zh_CN#rd',    
  },
  {
      name: '【跨境观察】外资入境篇之FDI（完结篇）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652562233&idx=2&sn=1944a5351fef4af768ca564019bf8343&chksm=8bbc8a55bccb03439f542610e8c963e0ebb832caeabe096c891f9052c3e4833bc51a6833a388&token=78874031&lang=zh_CN#rd',
  },
  {
      name: '最全梳理！拟备案产品、拟登记、拟注销管理人请注意！中基协AMBERS系统已优化！',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652561741&idx=1&sn=c1ccf18de30e7f0d7801320dc8dd6676&chksm=8bbc8821bccb0137ed140d15f02f2005a573202a4051be8dc53efcd493f6c653f0f8186c0c67&token=78874031&lang=zh_CN#rd',    
  },
  {
      name: '@私募管理人，五月合规日历已送达',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652561512&idx=2&sn=3912ce84bdf750aafdf855a8128f26da&chksm=8bbc8904bccb00128dcd881d2a9014256c26a1981863150e90661cd9c25cbf59e35dd9c24aaf&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '最新！中基协通知：AMBERS系统新功能将于5月1日正式上线运行',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652561512&idx=1&sn=0f101cb3de2ca14c8bd9cb060b63bd55&chksm=8bbc8904bccb0012d72e902c7b142151547c679e57fd30ee9b04311471700bba3d2955d42199&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '独家整理｜全国基金小镇注册持股平台及投资类机构政策梳理（2）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652561111&idx=2&sn=939746d0cec9ab2abe0fbb7fdb3b6a23&chksm=8bbcb7bbbccb3eade78a86d3017a45a0997545d77ed2b0d572ec36b49519e38c91eaa2eb8a77&token=1194533552&lang=zh_CN#rd',    
  },
  {
      name: '【跨境观察】外资入境篇之FDI（四）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652561063&idx=2&sn=f9c6807eef9d4afedd21d20585160542&chksm=8bbcb7cbbccb3edddda8f2fbfca9fef083da930a5d0cb35d007cc93954e6dcd5bb82395c458e&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '海南QFLP最新政策及落地流程研究',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652561063&idx=1&sn=ee5eb8e2edcfe206992369706512c44a&chksm=8bbcb7cbbccb3edd6a76ba7173091bcd4800232750ac4fbbcce96a86c89e4dfbb3b191e3e58e&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '私募管理人入会指南，入会不“南”！',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652560506&idx=1&sn=0c27d76ba14a6b832b532e88dc28ad56&chksm=8bbcb516bccb3c000f20402b8990a1c9ff257b92a5ca24edaa4c58634bcad11ec2f58c9ba16d&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '私募基金及管理人的涉税事项',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652560281&idx=2&sn=340b0173c4f55003558ead9cb900b327&chksm=8bbcb2f5bccb3be3f6524ca33bfd3326e29096f89c97e89b9227ca12adf1a3035a945af97e9d&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '独家整理｜全国基金小镇注册持股平台及投资类机构政策梳理（1）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652560229&idx=2&sn=84fa32ddfbced2dc8e5794acae7c9b5d&chksm=8bbcb209bccb3b1fd157bc0cbc32a96d6315326348bf44139525cd01b88fb7ff696a198078bf&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '剧透｜中基协将在未来出台有关私募登记备案的细化指引',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652560216&idx=1&sn=67dda1b382d00d300283a61c8c192fbf&chksm=8bbcb234bccb3b220bfff4a4b580a3b374d9dfa93d3c6929cac647cde2f2856f841edc308651&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '【跨境观察】外资入境篇之FDI（三）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652559684&idx=2&sn=626daa0dcf9660b49713d18f09240dcf&chksm=8bbcb028bccb393ebdac41dff6c8607e6d061831bfc62668c6b6c1db837d00f5e0fecede046d&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '重点梳理｜私募基金管理人登记申请材料清单新旧对比解读',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652559684&idx=1&sn=8d06158dacaa4c50cec273f69bb96d2e&chksm=8bbcb028bccb393e06510cdc584cecc157e54ca2d1e06b42750405480e471456d0c395cce10e&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '超完整年度合规日历，信披事项一清二楚！',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652559589&idx=1&sn=82d61f0ba20bf73863c47697d572b1be&chksm=8bbcb189bccb389f2213d6cef138564414162866c585a403f3d344bbd0bf14d3c23535bc4ae9&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '实操攻略｜中基协Ambers开放2022年度CRS填报',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652559273&idx=2&sn=fb66cbaf8d69eed8e318ced5e6791490&chksm=8bbcbec5bccb37d325b8a462b3e41de2c13b6f719036da261b47362b3bb658c9e53dd6dbe26a&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '新规下，私募基金《风险揭示书》应该如何写？（附范本）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652559036&idx=1&sn=2d736c3eb43c76f158f809c139fa5213&chksm=8bbcbfd0bccb36c6d32539a07c5b8869dbaafe3299f298740df65479cc02313458fa3c1e3883&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '【跨境观察】外资入境篇之FDI（二）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652559014&idx=2&sn=c776959c502a32f6288f5ed9757f78b2&chksm=8bbcbfcabccb36dc2bf5e413cd045828f91eceaa9c590a139a81bb13663bd07f72498799221c&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '干货分享｜一文读懂合伙企业的涉税事项',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652558818&idx=1&sn=a6f3cd96068bb2d7f35412423a3c82ea&chksm=8bbcbc8ebccb359860c887485ec9b03ef597a94f349349668e42aa03797e56a5ac3edb2a2613&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '私募基金管理费的收取方式和分级探讨',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652558769&idx=1&sn=69dc92f85c2f1dcb947ddb6ba932f28c&chksm=8bbcbcddbccb35cbd4ca036e3634603b3d51151e2d458df0da8ea598ada4446ca0bbfb3ceffe&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '【跨境观察】外资入境篇之FDI（一）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652558476&idx=2&sn=45888da17919825dc1455d2da5d89081&chksm=8bbcbde0bccb34f69364929d65e7f0b79a2ae848e6d6a2856e681e6a66349dca39eb8cc9eeb2&token=1194533552&lang=zh_CN#rd',
  },
  {
      name: '【跨境观察】离岸基金篇之新加坡（六）完结篇',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652557326&idx=2&sn=9b3fcf4be615c8ef74c7beeeb84c1082&chksm=8bbcb962bccb3074b40ebc099a57ce9b59dd794dffff968abcf7c83065f6ff53038dc430085a&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '实务｜CRS 报送常见问题汇总',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652556960&idx=1&sn=144da41be3b2c4ece6073ff667af7b40&chksm=8bbca7ccbccb2edab631e9faa470e4a2d10eaf8a2683a068495ac072ea1e564f5cde6b52dd2c&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '深度解读 | 关于实物分配股票方案（试点）的探索',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652556877&idx=1&sn=442ab99d75d189edd0cc0a0fa4f86a34&chksm=8bbca721bccb2e3733e375a01155b67c90902073813abc8547ae3af827fb7a1a130e00323b37&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '实务｜创业投资基金发改委备案的关注要点',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652556557&idx=1&sn=b23193e8c4ac8aa7b7cb44948cdfe3d2&chksm=8bbca461bccb2d77761a7984a2b7803ce7bc351b0f8dfe88648cfb766e2a3c172fbf21f9cd8c&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '【跨境观察】离岸基金篇之新加坡（五）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652556136&idx=2&sn=1d89f5634b600153629b827c4cea9289&chksm=8bbca204bccb2b12f469430a5f241cab5787be55fe9d9a3010233240a357c25ab6cae8d1758a&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '多层架构合伙企业涉税问题热点和难点',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652555891&idx=1&sn=b000a52d08cc27229486c88210113f7a&chksm=8bbca31fbccb2a0967089c363f5ee73278d569865731cbb16d8e8a827e480c57edad0e30b55d&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '私募基金监管处罚及处分全面梳理分析、合规建议、监管政策年度观察',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652555818&idx=1&sn=99656b22094b5dd265581544d9b88a09&chksm=8bbca346bccb2a50096951cb078e3ae9365112e11023a40e78e346869fd1c0e3df0718f4c798&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '【跨境观察】离岸基金篇之新加坡（四）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652555740&idx=1&sn=6556187dc68dc6a089e22426b3a16a40&chksm=8bbca0b0bccb29a645026ae343a332edf175194b5a68575f87ca134b3ecb8b44bee7a1972aa8&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '元年指南｜私募基金合规募集全流程一览（附募集相关材料清单下载）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652555740&idx=2&sn=4ede7b56c2692ba66a6845179b1b083b&chksm=8bbca0b0bccb29a66c1076cbc6f2d6e473b5d5ef22fe7aed46990ad7a65a84beb08d48014c92&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '关于私募股权投资基金结构化安排的合规性分析',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652555541&idx=1&sn=850ddd01cfae796e9bd89003ce78dd6a&chksm=8bbca079bccb296f61e1817faddb73fb806c7910707154d068c8df9415e828342a0fb2a011df&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '【跨境观察】离岸基金篇之新加坡（三）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652555496&idx=1&sn=1a998a0a05f30885808b0c54c21c823e&chksm=8bbca184bccb2892826c5c4c0197af46a21b34068246f663a36498b9e294e44ff77a14fa2464&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '压箱底整理｜私募产品的宣传推介要点',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652554457&idx=1&sn=62c44b3995dda57d66290bcbda766847&chksm=8bbcadb5bccb24a3cda4ccce5d2b68c95fe938d8a81a2523a9aca9c51beb41bce4d357a1d1ba&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '必看｜中基协登记备案新规热门问答Q&A',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652554356&idx=1&sn=34c4f2b47796b1c405fbd5bdb8d6b803&chksm=8bbcad18bccb240edafdf188da9876e85f0358f59d21b8d3315758f62d02dc75d738a569f461&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '【跨境观察】离岸基金篇之新加坡（二）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652554356&idx=2&sn=dbe66dfd26cb29c1e2b6831136f48943&chksm=8bbcad18bccb240e435280f1abb6bc625b19e6e69aa34c42ce4bf31b6d135b6a7f6ac36d59fe&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: 'QFLP实现外汇自由，增减资无需再办理外汇变更登记',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652554080&idx=2&sn=b3f93988e7e6d69a433c201ef74b4fbe&chksm=8bbcaa0cbccb231a5200411bc51a63651c36f6f0526836e637ab10a0b81b15f56d60e9e2a439&token=1820096898&lang=zh_CN#rd'
  },
  {
      name: '月度盘点｜私募基金二月备案数又遇冷（2023.02）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652553971&idx=2&sn=03e5a13d9f6b8e926b898d849d34ab87&chksm=8bbcab9fbccb228950afe60268a5bdfda3150b2e76428a6ac23d19f117d5f2f74ac328a53609&token=1869733064&lang=zh_CN#rd'
  },
  {
      name: '最新！中基协AMBERS系统开放不动产私募基金申请功能',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652553971&idx=1&sn=3aa424b55be025f9adcb3d4d09a6471c&chksm=8bbcab9fbccb2289ca5f8bbfb8c9535f80edf3fdb307d174f55fd69accf6f3586c18684afa12&token=1869733064&lang=zh_CN#rd'
  },
  {
      name: '直播回顾｜解读私募基金登记备案规则重要变化',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652553254&idx=2&sn=bf87e8e4dbff4cc420364f1de4d51816&chksm=8bbca94abccb205c889f068f63e6f3e8f3fae2f915bcd44a6c44ec71a12fbe0a2c7114891008&token=1869733064&lang=zh_CN#rd'
  },
  {
      name: 'QFLP新政！可直接获得QFLP资质，还有额度优势！',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652552818&idx=1&sn=1be40f3d902a3f8ad7e4036c9dddcba4&chksm=8bbcd71ebccb5e08526c7c4a2eee19be093676b6462109a62ec7899eb7eb29bd25c78d1896d5&token=1869733064&lang=zh_CN#rd'
  },
  {
      name: '上海市合格境外有限合伙人（QFLP）外汇新政将扩围至上海市全辖',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652551816&idx=2&sn=2e8b4adf2398cfcfb0dffd2871ec5513&chksm=8bbcd3e4bccb5af22843d034007c00001bab2d7ee21ff805ae43599f66b67f667b842d6fff5e&token=1869733064&lang=zh_CN#rd'
  },
  {
      name: '【跨境观察】离岸基金篇之新加坡（一）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652551816&idx=1&sn=d061561119138584969eeb45c90968ac&chksm=8bbcd3e4bccb5af21661b60bf8fff7b75a19bf541eab64d91b08e0ae81b9694f776f7e26bb7c&token=1869733064&lang=zh_CN#rd'
  },
  {
      name: '元年金服八周年——不负期待 日见新生！',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652550803&idx=1&sn=6d6c0e6035b5b0963fe4aba1eada7b7d&chksm=8bbcdfffbccb56e98117d302bffb2e1332eaa392df454129fbd4361e2dc2508166d8be9dcfae&token=1869733064&lang=zh_CN#rd'
  },
  {
      name: '【重磅发布】《私募基金跨境业务观察（2023）》',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652550410&idx=1&sn=b4a24a2e321efae186c7518722430e89&chksm=8bbcdc66bccb55709de6cc7b17b0db76309763751eaef2f6d33ff83a7015d916285ad4e34817&token=1869733064&lang=zh_CN#rd'
  },
  {
      name: '投资者回访是私募管理人必须要做的吗？',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652549443&idx=3&sn=fb4f4014ffd21adb16546282537723cd&chksm=8bbcd82fbccb51394491f3342820df08bc97df4cf79b5007018a22e63cefee454e6b0f032374&token=1869733064&lang=zh_CN#rd'
  },
  {
      name: '解锁私募基金管理人高管兼职限制',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652549359&idx=1&sn=b2e8ab64c9254f71b933a6d5ba6834e2&chksm=8bbcd983bccb50957c9b15e348943021e892d93c480016cbbb7967281f597224653195b4f34a&token=1869733064&lang=zh_CN#rd'
  },
  {
      name: '2023年元年金服招聘开始啦！',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652549121&idx=1&sn=6e82d33bf6be5883a346c3aa8daf6e58&chksm=8bbcd96dbccb507b1de2c8d9b5e9d7dd4dad6105e6c496019147d907dddb4100be90a78172be&token=1869733064&lang=zh_CN#rd'
  },
  {
      name: '月度盘点｜私募基金单月备案&管理人登记双遇冷（2023.01）',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652548845&idx=1&sn=df2bce5791e9c70eb45e46a473af8183&chksm=8bbcc781bccb4e9754f0279370272b509ac5b5715cf5cd841a243a29cd45faf054ed1434ecae&token=1869733064&lang=zh_CN#rd'
  },
  {
      name: '刚刚，实物股票分配首单试点落地！',
      link: 'https://mp.weixin.qq.com/s?__biz=MzA5NDE2NzA0Mw==&mid=2652548493&idx=2&sn=efd5d334f3273bf852be088bed97f8c0&chksm=8bbcc4e1bccb4df7d3adec77b38bd918feacc625fc84a960c54d21ecfaf80524cc8cc1436b9d&token=1869733064&lang=zh_CN#rd'
  },
]