<template>
  <div class="rmb">
    <topMenu></topMenu>
    <div class="logo_bg">
      <el-image :src="RMB">
        <div slot="placeholder">图片加载中</div>
      </el-image>
      <div class="main_title">人民币基金行政管理服务</div>
    </div>
    <!-- 大类 -->
    <div class="broad_category flex justify_content">
      <div class="item" v-for="(item, index) in rmbList" :key="index">
        <img :src="item.img" alt="">
        <p>{{item.name}}</p>
      </div>
    </div>
    <Contact :dialog="contactStatus"></Contact>
    <div class="introduce_bg">
      <!-- 管理人服务 -->
      <div class="manage flex">
        <div class="left">
          <img :src="rmb_1" alt="" style="width: 210px; height: 210px;">
        </div>
        <div class="right">
          <div class="title">管理人服务</div>
          <div class="content">
            <ul class="flex" style="flex-wrap: wrap;">
              <li>管理人设立咨询</li>
              <li>年度审计咨询</li>
              <li>代理记账</li>
              <li>协会三大系统信息维护咨询</li>
              <li>管理人重大事项变更咨询</li>
              <li>合规/税法咨询</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 基金全生命周期服务* -->
      <div class="foundation flex justify_content">
        <div class="left">
          <div class="title" style="color: #fff;">基金全生命周期服务*</div>
          <div class="content">
            <ul class="flex" style="flex-wrap: wrap;">
              <li>备案咨询</li>
              <li>份额登记、估值核算</li>
              <li>基金小镇对接</li>
              <li>基金缴款/分配通知书</li>
              <li>募集户/托管户对接</li>
              <li>监管报表编制</li>
              <li>年度审计咨询</li>
              <li>投资者报告（含资本账户报告），发放投资者信函，为投资者答疑</li>
              <li>代理记账、税务申报</li>
              <li>基金清算</li>
            </ul>
          </div>
        </div>
        <div class="right">
          <img :src="rmb_4" alt="" style="width: 200px; height: 200px;">
          <p>*部分服务联合相应专业机构提供</p>
        </div>
      </div>
      <!-- 元利签电子签约服务 -->
      <div class="manage flex">
        <div class="left">
          <img :src="rmb_3" alt="" style="width: 210px; height: 210px;">
        </div>
        <div class="right">
          <div class="title">元利签电子签约服务</div>
          <div class="content">
            <ul>
              <li>适当性系统</li>
              <li>线上合同管理账</li>
              <li>电子合同签约</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="tip">
      <p>同时,元年金服还提供QFLP、QDLP申请、日常基金事务合规咨询、标的公司财务分析报告咨询等私募</p>
      <p>基金配套服务,为私募基金管理人客户在基金日常运营事务中提供全生命周期的业务支持。</p>
    </div>
    <div class="footer" id="footer">
      <FooterBottom></FooterBottom>
    </div>
  </div>
</template>

<script>
import TopMenu from '../menu.vue';
import Contact from '../contact';
import FooterBottom from '../footer';
import RMB from '../../assets/rmb_bg.png';
import rmb_1 from '../../assets/rmb_1.png';
import rmb_2 from '../../assets/rmb_2.png';
import rmb_3 from '../../assets/rmb_3.png';
import rmb_4 from '../../assets/rmb_4.png';

export default {
  name: 'RMB',
  components: {TopMenu, Contact, FooterBottom},
  data() {
    return {
      RMB,
      rmb_1,
      rmb_2,
      rmb_3,
      rmb_4,
      rmbList: [
        { img: rmb_1, name: '管理人服务' },
        { img: rmb_2, name: '基金全生命周期服务*' },
        { img: rmb_3, name: '元利签电子签约服务' },
      ],
      contactStatus: false,
    }
  }
}  
</script>

<style scoped lang="scss">
  .rmb {
    /*大背景*/
    .logo_bg{
      position: relative;
      padding-top: 90px;
      .main_title {
        position: absolute;
        left: 110px;
        bottom: 25%;
        font-size: 60px;
        font-weight: bold;
        color: #fff;
      }
    }
    .logo_bg .el-image{
      height: 100%;
      width: 100%;
    }
    /*大类 */
    .broad_category {
      padding: 60px 210px 80px 210px;
      .item {
        width: 31.5%;
        border: 1px solid #E0E0E0;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        text-align: center;
        img {
          width: 170px;
          height: 170px;
          margin: 30px 0;
        }
        p {
          color: #21374E;
          font-size: 40px;
          font-weight: bold;
          padding-bottom: 50px;
        }
      }
    }
    .title {
      font-size: 40px;
      font-weight: bold;
      color: #21374E;
      padding-bottom: 30px;
    }
    ul {
      padding-left: 25px;
    }
    ul>li {
      font-size: 20px;
      line-height: 40px;
    }
    .introduce_bg {
      background: url("../../assets/introduce_bg.png");
    }
    /*管理人服务 */
    .manage {
      padding: 60px 210px;
      .left {
        width: 45%;
      }
      .right {
        width: 55%;
        ul>li:nth-child(1), ul>li:nth-child(3), ul>li:nth-child(5){
          width: 62%;
        }
      }
    }
    /*基金全生命周期服务 */
    .foundation {
      padding: 30px 210px;
      height: 354px;
      background: url("../../assets/rmb_5.png");
      color: #fff;

      .left {
        width: 75%;
        ul>li:nth-child(1), ul>li:nth-child(3), ul>li:nth-child(5), ul>li:nth-child(7), ul>li:nth-child(9){
          width: 40%;
        }
        ul>li:nth-child(2), ul>li:nth-child(4), ul>li:nth-child(6), ul>li:nth-child(8), ul>li:nth-child(10){
          width: 60%;
        }
      }
      .right {
        width: 25%;
        text-align: right;
        img {
          margin-top: 30px;
        }
        p {
          font-size: 18px;
          padding-right: 20px;
          padding-top: 40px;
        }
      }
    }
    .tip {
      background: url("../../assets/footer_1.png");
      color: #fff;
      text-align: center;
      font-size: 20px;
      padding: 80px 0 40px;
      line-height: 35px;
    }
  }
  
</style>