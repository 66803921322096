<template>
    <div class="top_menu">
      <div class="container">
        <div class="logo_wrap">
          <img :src="logo" alt="" class="logo">
        </div>
        <div class="nav_wrap">
          <el-menu mode="horizontal" router>
            <el-menu-item :index="language === 'chinese' ? '/' : '/english'" @click="scrollToTop">首页</el-menu-item>
            <el-menu-item>
              <div @click="handleClick('元年介绍', '#introduce', language === 'chinese' ? '/' : '/english')">元年介绍</div>
            </el-menu-item>
            <el-submenu index="/services">
              <template slot="title"><span style="color: #fff;">服务范围</span></template>
              <el-menu-item :index="language === 'chinese' ? '/services/RMB' : '/services/RMBEnglish'">人民币基金行政管理服务</el-menu-item>
              <el-menu-item :index="language === 'chinese' ? '/services/dollar' : '/services/dollarEnglish'">美元基金行政管理服务</el-menu-item>
              <el-menu-item index="/services/manage" v-if="language === 'chinese'">资管机构中后台运营全方位专业支持</el-menu-item>
            </el-submenu>
            <el-menu-item v-if="language === 'chinese'">
              <div @click="handleClick('新闻资讯', '#new', '/')">新闻资讯</div>
            </el-menu-item>
            <el-menu-item v-if="language === 'chinese'">
              <div @click="handleClick('加入我们', '#my', '/')">加入我们</div>
            </el-menu-item>
            <el-menu-item v-if="language === 'chinese'">
              <div @click="handleClick('联系我们', '#footer', '/')">联系我们</div>
            </el-menu-item>
          </el-menu>
          <el-button class="lang_btn" type="text" style="color: #fff; font-size: 18px;">
            <span @click="scrollToTop">中文</span> | <span @click="chooseLanguage('/english')">EN</span>
          </el-button>
        </div>
      </div>
        <!-- <div :class="[currentMenu === '首页'?'is_active':'']" class="item home_item" @click="handleClick('首页')"
             @mouseenter="handleMouseenter('首页')"
             @mouseleave="handleMouseleave"
        >
            <a href="/">
                <img src="@/assets/home_icon.png" alt="" width="28px">
            </a>
            <span class="text">首&nbsp;&nbsp;&nbsp;&nbsp;页</span>
        </div>
        <div :class="[currentMenu === '新闻资讯'?'is_active':'']" class="item" @click="handleClick('新闻资讯','#news')"
             @mouseenter="handleMouseenter('新闻资讯')"
             @mouseleave="handleMouseleave"
        >
            <img src="@/assets/news_icon.png" alt="" width="28px">
            <span class="text">新闻资讯</span>
        </div>
        <div :class="[currentMenu === '加入我们'?'is_active':'']" class="item" @click="handleClick('加入我们','#join')"
             @mouseenter="handleMouseenter('加入我们')"
             @mouseleave="handleMouseleave"
        >
            <img src="@/assets/join_icon.png" alt="" width="28px">
            <span class="text">加入我们</span>
        </div>
        <div :class="[currentMenu === '关于我们'?'is_active':'']" class="item" @click="handleClick('关于我们','#about')"
             @mouseenter="handleMouseenter('关于我们')"
             @mouseleave="handleMouseleave"
        >
            <img src="@/assets/about_icon.png" alt="" width="28px">
            <span class="text">关于我们</span>
        </div>
        <div :class="[currentMenu === '元年风采'?'is_active':'']" class="item" @click="handleClick('元年风采','#footer')"
             @mouseenter="handleMouseenter('元年风采')"
             @mouseleave="handleMouseleave"
        >
            <img src="@/assets/footer_icon.png" alt="" width="28px">
            <span class="text">元年风采</span>
        </div> -->
    </div>
</template>
<script>
import logo from '../assets/logo.png';
import { detectZoom } from '@/utils/detectZoom';

export default {
    name: 'TopMenu',
    props:{
      isPosition:{
          type:Boolean,
          default: false
      },
      language: {
        type: String,
        default: 'chinese'
      }
    },
    data() {
      return {
        currentMenu:'',
        logo,
        devicePixelRatio: 0
      }
    },
    created() {
      const m = detectZoom();
      this.devicePixelRatio = 100/Number(m);
    },
    methods:{
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth" // 平滑滚动到顶部
        });
      },
      handleClick(menuItem,dom,path){
        if(!dom){
          return;
        }
        if(this.isPosition){
          window.location.href='/'+dom
        }else {
          // document.querySelector(dom).scrollIntoView({
          //     behavior:'smooth'
          // })
          if (this.$route.path !== '/') {
            this.$router.push(path);
          }
          setTimeout(() => {
            document.querySelector(dom).scrollIntoView({
              behavior:'smooth',
              block: 'center',
            })
            // const element = document.querySelector(dom);
            // const rect = element.getBoundingClientRect();
            // const offset = 90; // 设置一个适当的偏移量，根据实际情况调整
            // window.scrollTo({
            //   top: rect.top + window.pageYOffset - offset,
            //   behavior: 'smooth'
            // });
          }, 100)
        }
      },
      chooseLanguage(path) {
        this.$nextTick(() => {
          this.$router.push(path);
        })
      },
      handleMouseenter(menuItem){
        this.currentMenu = menuItem;
      },
      handleMouseleave(){
        this.currentMenu = '';
      }
    }
  }
</script>
<style>
  @media screen and (max-width: 1920px) and (max-height: 1080px) and (-webkit-device-pixel-ratio: 1.5) {
  /* 添加针对150%缩放设置下的二级导航样式 */
  .el-menu.el-menu--popup {
    left: 245px !important;
  }
}
</style>
<style scoped lang="scss">
  .top_menu{
    width: 100%;
    height: 90px;
    background: rgba(33,55,78,1);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    position: fixed;
    top:0;
    left: 0;
    z-index: 100;
    
    .container {
      padding: 0 110px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-menu {
        background: transparent !important;
      }
      .el-menu.el-menu--horizontal {
        border-bottom: none !important;
      }
      .el-menu--horizontal>.el-menu-item {
        font-size: 18px;
        color: #fff;
      }
      .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
        color: #fff !important;
      }
      .el-menu--horizontal>.el-submenu .el-submenu__title span{
        font-size: 18px !important;
      }
      .el-menu--horizontal>.el-submenu i.el-submenu__icon-arrow{
        font-size: 18px !important;
        color: #fff !important;
      }
      .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
        background: transparent !important;
      }
    }
    .logo {
      width: 267px;
      height: 44px;
    }
    .nav_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
    }

    .lang_btn {
      margin-left: 20px;
    }
    .lang_btn span:hover {
      font-weight: bold;
    }
  }
  
    // a{
    //     color: #101010;
    //     font-size: 14px;
    // }
    // /*菜单*/
    // .top_menu{
    //     width: 100%;
    //     height: 108px;
    //     background: red;
    //     position: fixed;
    //     top:0;
    //     left: 0;
    //     z-index: 100;
    //     display: flex;
    //     flex-flow: column;
    //     justify-content: space-around;
    //     align-items: center;
    //     .item{
    //         position: relative;
    //         background: #21374E;
    //         .text{
    //             position: absolute;
    //             top:10px;
    //             left:78px;
    //             display: none;
    //             width: 96px;
    //             height: 49px;
    //             background: #21374E;
    //             color: #E5E5E5;
    //             font-size: 18px;
    //             line-height: 49px;
    //             text-align: center;
    //             border-radius: 3px;
    //         }
    //     }
    //     .home_item{
    //         position: relative;
    //         &::before{
    //             content: '';
    //             display: block;
    //             width: 30px;
    //             height: 1px;
    //             background: #e5e5e570;
    //             position: absolute;
    //             left: 20px;
    //             bottom: 0;
    //         }
    //     }
    // }
    // .top_menu div{
    //     cursor: pointer;
    //     display: flex;
    //     align-items: center;
    //     padding: 21px;
    // }
    // .top_menu svg {
    //     margin-right: 10px;
    //     margin-left: 14px;
    // }
    // .is_active{
    //     &.item{
    //         background: #28445E;
    //     }
    //     .text{
    //         display: block!important;
    //     }
    // }
</style>
