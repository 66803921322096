import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../components/HomePage.vue';
import HomePageEnglish from '../components/HomePageEnglish.vue';
import RMBServices from '../components/services/RMB.vue';
import RMBEnglishServices from '../components/services/RMBEnglish.vue';
import DollarServices from '../components/services/dollar.vue';
import DollarEnglishServices from '../components/services/dollarEnglish.vue';
import ManageServices from '../components/services/manage.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/english',
    name: 'English',
    component: HomePageEnglish
  },
  {
    path: '/services/RMB',
    name: 'RMB',
    component: RMBServices
  },
  {
    path: '/services/RMBEnglish',
    name: 'RMBEnglish',
    component: RMBEnglishServices
  },
  {
    path: '/services/dollar',
    name: 'Dollar',
    component: DollarServices
  },
  {
    path: '/services/dollarEnglish',
    name: 'dollarEnglish',
    component: DollarEnglishServices
  },
  {
    path: '/services/manage',
    name: 'Manage',
    component: ManageServices
  },
  {
    path: '/position',
    name: 'Position',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../components/position.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router
