import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import BaiduMap from 'vue-baidu-map'

Vue.config.productionTip = false

// 处理笔记本系统默认系统比例为150%带来的布局影响
import { detectZoom } from '@/utils/detectZoom';
const m = detectZoom();
document.body.style.zoom = 100 / Number(m);

Vue.use(Element);
Vue.use(BaiduMap, {
  ak: 'uuxGoRbLuRzfQaEhMGxSNW73ID6cgoln'  //  在此输入你自己的百度地图ak
});
new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
